import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserProfileContext, { UserProfileContextState } from 'context/UserProfileContext';
import { CurrentUserModel } from 'core/domain/users/models/userModel';
import { GetCurrentUser } from 'core/domain/users/repositories/getCurrentUser';
import { SetLogout } from 'core/domain/login/repositories/Logout';
import { useEnvironment } from './useEnvironment';
import { useMessage } from './useMessage';

interface useProfileUserResponse {
  data: CurrentUserModel | null;
  loading: boolean;
  userProfileLoading: boolean;
  error: boolean;
  onRefreshProfile: () => void;
}

export const useProfileUser = (): useProfileUserResponse => {
  const { t } = useTranslation();
  const { userProfile, userProfileLoading, setIsUserProfileLoading, setUserProfile } =
    useContext<UserProfileContextState>(UserProfileContext);
  const { setMessageError } = useMessage();
  const { host } = useEnvironment();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const onRefreshProfile = useCallback(async () => {
    setIsLoading(true);
    setIsUserProfileLoading(true);
    try {
      const data = await GetCurrentUser();
      setUserProfile(data);
    } catch (error) {
      await SetLogout(host);
      setIsError(true);
      setMessageError({ description: t('_CURRENT_USER_ERROR_GETTING_DATA') });
    } finally {
      setIsLoading(false);
      setIsError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setUserProfile]);

  return {
    data: userProfile,
    loading: isLoading,
    userProfileLoading,
    error: isError,
    onRefreshProfile,
  };
};
