import { MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getAssetPathById, getAssetsWithPaginationPath, ROUTE_PATH_ASSET_QR_TOOL } from 'components/pages/App/routes/assets/config';
import { hasPermission } from 'services/permissions';
import { UserPermissionType } from 'models/users.model';
import { useCreateAsset } from './useCreateAsset';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { Asset } from 'models/assets.model';
import { getAssets } from 'services/api/assets';
import { AssetsColumns } from 'components/molecules/AssetsColumns';

export const useAssets = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const [searchQuery, setSearchQuery] = useState('');
  const [isCharging, setIsCharging] = useState(false);
  const [totalPages, setTotalPages] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageCurrent, setPageCurrent] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [assets, setAssets] = useState<Asset[]>([]);
  const [pageSize, setPageSize] = useState(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const { createAssetDrawerOptions, qrScannerOptions, didScannerOptions, clientOptions, qrErrorModalOptions, successCreatingAsset } =
    useCreateAsset();

  const hasPermissionToGenerateQr = hasPermission(UserPermissionType.GENERATE_QR_CODES);

  const literals = {
    title: t('assets'),
    createAssetButtonText: t('new_asset'),
    qrGeneratorButtonText: t('qr_generator'),
  };

  const onNavigateToQrToolPage = () => {
    history.push(ROUTE_PATH_ASSET_QR_TOOL);
  };

  const onSelectAsset = ({ id }: Asset) => {
    history.push(getAssetPathById(id));
  };

  const onOpenLink = (assetId: string, event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const url = `${window.location.href}/${assetId}/start`;
    window.open(url, '_blank');
  };

  const columns = AssetsColumns(onSelectAsset, onOpenLink);

  const getAssetsWithPagination = async ({ current, size, search }: { current: number; size: number; search?: string }) => {
    setIsCharging(true);
    try {
      const assetsResponse = await getAssets({ current, size, search });
      assetsResponse && setAssets(assetsResponse.data);
      assetsResponse && setTotalPages(assetsResponse.meta.total_pages);
    } catch (error) {
      console.error(error);
    } finally {
      setIsCharging(false);
    }
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    setPageCurrent(Number(newPage));
    setPageSize(Number(newSize));
  }, [page, size]);

  const onChangePage = (page: number, pageSize?: number) => {
    setPageCurrent(page);
    setPageSize(pageSize!);
    history.push(getAssetsWithPaginationPath({ page: String(page), size: String(pageSize!) }));
  };

  const onSearch = (value: string) => {
    setSearchQuery(value);
    setPageCurrent(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  };

  useEffect(() => {
    getAssetsWithPagination({
      current: Number(page) || Number(TABLE_PAGINATION_PAGE_DEFAULT),
      size: Number(size) || Number(TABLE_PAGINATION_SIZE_DEFAULT),
      search: searchQuery,
    });
  }, [page, size, searchQuery]);

  useEffect(() => {
    !!successCreatingAsset &&
      getAssetsWithPagination({ current: Number(TABLE_PAGINATION_PAGE_DEFAULT), size: Number(TABLE_PAGINATION_SIZE_DEFAULT) });
  }, [successCreatingAsset]);

  return {
    assets,
    columns,
    totalPages,
    pageCurrent,
    pageSize,
    literals,
    createAssetDrawerOptions,
    qrScannerOptions,
    didScannerOptions,
    clientOptions,
    qrErrorModalOptions,
    onSelectAsset,
    onNavigateToQrToolPage,
    onSearch,
    onChangePage,
    isCharging,
    hasPermissionToGenerateQr,
  };
};
