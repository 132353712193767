import { FC } from 'react';
import Error from 'assets/svg/icon/Error';
import { ActiveUsersDashboardProps } from 'components/pages/DashboardPage/resources/models';
import './styles.scss';

const DashboardUsersErrorTemplate: FC<ActiveUsersDashboardProps> = ({ literals }) => {
  const { title, titleTimePeriod, errorTitle, errorDescription } = literals;

  return (
    <div className='UsersErrorTemplate__container'>
      <div className='UsersErrorTemplate__header'>
        <p className='UsersErrorTemplate__title'>{title}</p>
        <p className='UsersErrorTemplate__subtitle'>{titleTimePeriod}</p>
      </div>
      <div className='UsersErrorTemplate__message-container'>
        <div className='UsersErrorTemplate__message-content'>
          <Error />
          <p className='UsersErrorTemplate__message-title'>{errorTitle}</p>
          <p className='UsersErrorTemplate__message-description'>{errorDescription}</p>
        </div>
      </div>
    </div>
  );
}

export default DashboardUsersErrorTemplate;