import {
  GatewayUpdateLatestModulesVersionsModel,
  GatewayUpdateLatestModulesVersionsPayload,
  GatewayUpdateProcessType,
  GatewayModuleVersionUpdateStateModel,
  GatewayModuleVersionUpdateStatePayload,
  GatewayAssetModel,
  GatewayAssetPayload,
  GatewayListIncludedAssetModel,
  GatewayListIncludedAssetPayload,
  GatewaysListDataModel,
  GatewaysListDataPayload,
  GatewaysListIncludedModel,
  GatewaysListIncludedPayload,
  GatewaysListResponseModel,
  GatewaysListResponsePayload,
  UpdateGatewayDataModel,
  UpdateGatewayDataPayload,
  UpdateGatewayDataSharedSensorModel,
  UpdateGatewayDataSharedSensorsPayload,
  UpdateGatewayDataPayloadType,
  GatewayListMetaPayload,
  GatewayListMetaModel,
} from '../model';

export const payloadToGatewayModuleVersionUpdateState = ({
  uuid,
  state,
}: GatewayModuleVersionUpdateStatePayload): GatewayModuleVersionUpdateStateModel => {
  return {
    state: (state as GatewayUpdateProcessType) ?? GatewayUpdateProcessType.COMPLETED,
    uuid: uuid ?? '',
  };
};

export const payloadToLastGatewayModulesVersions = ({
  aass,
  system,
}: GatewayUpdateLatestModulesVersionsPayload): GatewayUpdateLatestModulesVersionsModel => {
  return {
    aass: aass ?? '',
    system: system ?? '',
  };
};

const payloadToGatewayDataAssetFromList = ({ alias, id, project }: GatewayAssetPayload): GatewayAssetModel => {
  return {
    alias: alias ?? '',
    id: id ?? '',
    project: project ?? '',
  };
};

const payloadToGatewayDataFromList = ({
  assets,
  assistant_cloud,
  batch_id,
  batch_name,
  daemon_version,
  deployed_at,
  dns_url,
  firmware_version,
  gateway_version,
  helper_version,
  hw_version,
  id,
  knx_version,
  management_links,
  master_user,
  os_version,
  production_date,
  project_id,
  project_alias,
  qr_code,
  sku,
  type,
  voice_assistant_user,
  zwave_version,
}: GatewaysListDataPayload): GatewaysListDataModel => {
  const transformedAssets = assets.map((item) => payloadToGatewayDataAssetFromList(item)) ?? [];

  return {
    assets: transformedAssets,
    assistantCloud: assistant_cloud ?? '',
    batchId: batch_id ?? '',
    batchName: batch_name ?? '',
    daemonVersion: daemon_version ?? '',
    deployedAt: deployed_at ?? 0,
    dnsUrl: dns_url ?? '',
    firmwareVersion: firmware_version ?? '',
    gatewayVersion: gateway_version ?? '',
    helperVersion: helper_version ?? '',
    hwVersion: hw_version ?? '',
    id: id ?? '',
    knxVersion: knx_version ?? '',
    managementLinks: management_links ?? [],
    masterUser: master_user ?? '',
    osVersion: os_version ?? '',
    productionDate: production_date ?? 0,
    projectId: project_id ?? '',
    projectAlias: project_alias ?? '',
    qrCode: qr_code ?? '',
    sku: sku ?? '',
    type: type ?? '',
    voiceAssistantUser: voice_assistant_user ?? '',
    zwaveVersion: zwave_version ?? '',
  };
};

const payloadToGatewayIncludedAssetFromList = ({
  alias,
  apartment,
  block_number,
  city,
  country,
  id,
  latitude,
  longitude,
  state,
  street_name,
  street_type,
  zip_code,
}: GatewayListIncludedAssetPayload): GatewayListIncludedAssetModel => {
  return {
    alias: alias ?? '',
    apartment: apartment ?? '',
    blockNumber: block_number ?? '',
    city: city ?? '',
    country: country ?? '',
    id: id ?? '',
    latitude: latitude ?? '',
    longitude: longitude ?? '',
    state: state ?? '',
    streetName: street_name ?? '',
    streetType: street_type ?? '',
    zipCode: zip_code ?? '',
  };
};

const payloadToGatewayIncludedFromList = ({ assets }: GatewaysListIncludedPayload): GatewaysListIncludedModel => {
  const transformedAssets = assets.map((item) => payloadToGatewayIncludedAssetFromList(item)) ?? [];
  return { assets: transformedAssets };
};

const payloadToGatewayMetaFromList = ({ all_results, page_size, results, total_pages }: GatewayListMetaPayload): GatewayListMetaModel => {
  return {
    allResults: all_results ?? 1,
    pageSize: page_size ?? 1,
    results: results ?? 1,
    totalPages: total_pages ?? 1,
  };
};

export const payloadToGatewayFromList = ({ data, meta, included, links }: GatewaysListResponsePayload): GatewaysListResponseModel => {
  const transformedData = data.map((item) => payloadToGatewayDataFromList(item)) ?? [];
  const transformedIncluded = payloadToGatewayIncludedFromList(included);
  const transformedMeta = payloadToGatewayMetaFromList(meta);

  return {
    data: transformedData,
    meta: transformedMeta,
    included: transformedIncluded,
    links: {
      first: links.first ?? '',
      last: links.last ?? '',
      next: links.next ?? '',
      prev: links.prev ?? '',
      self: links.self ?? '',
    },
  };
};

const gatewayDetailSharedSensorToPayload = ({
  sensorUuid,
  assets,
}: UpdateGatewayDataSharedSensorModel): UpdateGatewayDataSharedSensorsPayload => {
  return {
    sensor_uuid: sensorUuid,
    assets,
  };
};

const gatewayDetailToPayloadManager = {
  [UpdateGatewayDataPayloadType.ALIAS]: (gateway: UpdateGatewayDataModel) => ({ alias: gateway[UpdateGatewayDataPayloadType.ALIAS] }),
  [UpdateGatewayDataPayloadType.QR_GATEWAY_ID]: (gateway: UpdateGatewayDataModel) => ({
    qr_gateway_id: gateway[UpdateGatewayDataPayloadType.QR_GATEWAY_ID],
  }),
  [UpdateGatewayDataPayloadType.NAME]: (gateway: UpdateGatewayDataModel) => ({ name: gateway[UpdateGatewayDataPayloadType.NAME] }),
  [UpdateGatewayDataPayloadType.PROJECT_ID]: (gateway: UpdateGatewayDataModel) => ({
    project_id: gateway[UpdateGatewayDataPayloadType.PROJECT_ID],
  }),
  [UpdateGatewayDataPayloadType.VENDOR]: (gateway: UpdateGatewayDataModel) => ({ vendor: gateway[UpdateGatewayDataPayloadType.VENDOR] }),
  [UpdateGatewayDataPayloadType.SHARED_SENSORS]: (gateway: UpdateGatewayDataModel) => ({
    shared_sensors: gateway[UpdateGatewayDataPayloadType.SHARED_SENSORS]?.map((item) => gatewayDetailSharedSensorToPayload(item)),
  }),
};

export const gatewayDetailToPayload = (gateway: UpdateGatewayDataModel): UpdateGatewayDataPayload => {
  const gatewaysKeys = Object.keys(gateway);
  const dataArray = gatewaysKeys.map((item) => gatewayDetailToPayloadManager[item as UpdateGatewayDataPayloadType](gateway));
  return Object.assign({}, ...dataArray);
};
