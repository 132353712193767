import { FC } from 'react';
import Error from 'assets/svg/icon/Error';
import './styles.scss';
import { ActiveGatewaysDashboardProps } from 'components/pages/DashboardPage/resources/models';

const DashboardActiveGatewaysErrorTemplate: FC<ActiveGatewaysDashboardProps> = ({ literals }) => {
  const {
    title,
    subtitle,
    text,
    chartSubtitle,
    errorTitle,
    errorDescription,
  } = literals;

  return (
    <div className={'DashboardActiveGatewaysErrorTemplate__wrapper'}>
      <div className={'DashboardActiveGatewaysErrorTemplate__data'}>
        <div className='DashboardActiveGatewaysErrorTemplate__data-header'>
          <p className='DashboardActiveGatewaysErrorTemplate__title'>{title}</p>
          <p className='DashboardActiveGatewaysErrorTemplate__subtitle'>{subtitle}</p>
        </div>
        <div className='DashboardActiveGatewaysErrorTemplate__data-content'>
          <p className='DashboardActiveGatewaysErrorTemplate__data-content-info'>{text}</p>
          <p className='DashboardActiveGatewaysErrorTemplate__data-content-info-value'>-</p>
        </div>
      </div>
      <div className='DashboardActiveGatewaysErrorTemplate__chart'>
        <div className='DashboardActiveGatewaysErrorTemplate__chart-header'>
          <p className='UsersErrorTemplate__subtitle'>{chartSubtitle}</p>
        </div>
        <div className='DashboardActiveGatewaysErrorTemplate__chart-content'>
          <div className='DashboardActiveGatewaysErrorTemplate__chart-content-info'>
            <Error />
            <p className='DashboardActiveGatewaysErrorTemplate__chart-content-info-title'>{errorTitle}</p>
            <p className='DashboardActiveGatewaysErrorTemplate__chart-content-info-description'>{errorDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardActiveGatewaysErrorTemplate;