import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';
import { DeviceModel, DevicesResponsePayload } from '../models';
import { payloadToDevicesList } from '../mappers';

const getUrl = ({
  host,
  projectId,
  assetId,
  gatewayId,
}: {
  host: string;
  projectId?: string;
  assetId?: string;
  gatewayId?: string;
}): string => {
  const params = new URLSearchParams();
  projectId && params.append('project_id', projectId);
  assetId && params.append('asset_id', assetId);
  gatewayId && params.append('gateway_id', gatewayId);

  return `${host}/devices?${params}`;
};

export const GetDevicesLists = async ({
  host,
  token,
  projectId,
  assetId,
  gatewayId,
}: {
  host: string;
  token: string;
  projectId?: string;
  assetId?: string;
  gatewayId?: string;
}): Promise<DeviceModel[]> => {
  try {
    const url = getUrl({ host, projectId, assetId, gatewayId });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<DevicesResponsePayload>;
    return payloadToDevicesList(data.data);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
