import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { payloadToGatewayModuleVersionUpdateState } from '../mappers';
import { GatewayModuleVersionUpdateStateEntity } from '../entities';
import { GatewayModuleType, GatewayModuleVersionUpdateStateModel, GatewayModuleVersionUpdateStatePayload } from '../model';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = ({ host, gatewayId, module }: { host: string; gatewayId: string; module: GatewayModuleType }) => {
  return `${host}/gateway-updates/updates/${gatewayId}/${module}/latest_state`;
};

export const CheckGatewayModuleVersionUpdateState = async ({
  gatewayId,
  module,
  token,
  host,
}: {
  gatewayId: string;
  module: GatewayModuleType;
  token: string;
  host: string;
}): Promise<GatewayModuleVersionUpdateStateModel> => {
  try {
    const url = getUrl({ host, gatewayId, module });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = (await axios(options)) as AxiosResponse<GatewayModuleVersionUpdateStatePayload>;
    const mappedData = payloadToGatewayModuleVersionUpdateState(data);
    return GatewayModuleVersionUpdateStateEntity(mappedData);
  } catch (error) {
    throw getErrorFormat(error);
  }
};
