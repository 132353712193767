import { FC } from 'react';
import {
  ActiveGatewaysDashboardProps,
  ActiveUsersDashboardProps,
  OccupationDashboardProps,
  SupportTicketsDashboardProps,
} from 'components/pages/DashboardPage/resources/models';
import DashboardOccupationTemplate from '../DashboardOccupationTemplate';
import DashboardActiveUsersTemplate from '../DashboardActiveUsersTemplate';
import DashboardSupportTicketsTemplate from '../DashboardSupportTicketsTemplate';
import DashboardActiveGatewaysTemplate from '../DashboardActiveGatewaysTemplate';
import './styles.scss';

interface Props {
  occupation: OccupationDashboardProps;
  activeUsers: ActiveUsersDashboardProps;
  supportTickets: SupportTicketsDashboardProps;
  activeGateways: ActiveGatewaysDashboardProps;
}

const DashboardTemplate: FC<Props> = ({
  occupation,
  activeUsers,
  supportTickets,
  activeGateways,
}) => {
  return (
    <div className='Dashboard__wrapper'>
      <div className='Dashboard__charts'>
        <DashboardOccupationTemplate {...occupation} />
        <DashboardActiveUsersTemplate {...activeUsers} />
      </div>
      <DashboardSupportTicketsTemplate {...supportTickets} />
      <DashboardActiveGatewaysTemplate {...activeGateways} />
    </div>
  );
}

export default DashboardTemplate;