import { MouseEvent } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constants/date';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';
import { Icon } from 'components/atoms/Icons';
import './styles.scss';

interface Props {
  onClick: (asset: ProjectAssetModel, tab?: 'start' | 'users' | 'devices' | 'consumptions' | 'events' | 'permissions') => void;
  onOpenLink: (id: string, event: MouseEvent<HTMLButtonElement>) => void;
  assetOccupiedText: string;
  assetNotOccupiedText: string;
  assetConnectedText: string;
  assetDisconnectedText: string;
  assetNoLinkedGatewayText: string;
  assetDefaultText: string;
}

export const ProjectAssetsColumns = ({
  onClick,
  onOpenLink,
  assetOccupiedText,
  assetNotOccupiedText,
  assetConnectedText,
  assetDisconnectedText,
  assetNoLinkedGatewayText,
  assetDefaultText,
}: Props): AlfredTableColumn<ProjectAssetModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('address'),
      key: 'address',
      width: '70%',
      render: (text, record) => {
        return (
          <>
            <div>
              {`${record.streetType} ${record.streetName} ${record.blockNumber}, ${record.apartment}, ${record.city}, ${record.state}, ${record.country}`}
            </div>
            {record.createdAt && <span className='Text__date'>{moment.unix(Number(record.createdAt)).format(DATE_TIME_FORMAT)}</span>}
          </>
        );
      },
    },
    {
      title: t('alias'),
      key: 'alias',
      width: '20%',
      render: (text, { alias }) =>
        alias ? <div>{alias}</div> : <Typography.Text disabled>{t('without_alias')}</Typography.Text>,
    },
    {
      key: 'link',
      width: '5%',
      render: (text, { id }) => (
        <button className={'ProjectAssetsColumns__linkButton'} onClick={(event) => onOpenLink(id, event)}>
          <Icon.LinkNewTab />
        </button>
      ),
    },
    // {
    //   title: t('_ASSET_OCCUPATION_TITLE'),
    //   key: 'isOccupied',
    //   width: '20%',
    //   render: (text, { isOccupied }) =>
    //     <div>{isOccupied ? assetOccupiedText : assetNotOccupiedText}</div>,
    // },
    // {
    //   title: t('_ASSET_GATEWAY_CONNECTION_STATUS_TITLE'),
    //   key: 'alias',
    //   width: '20%',
    //   render: (text, { gatewayConnectionStatus }) =>
    //     <ProjectAssetGatewayConnectionStatusManager
    //       text={gatewayConnectionStatus}
    //       connectedText={assetConnectedText}
    //       disconnectedText={assetDisconnectedText}
    //       noLinkedGatewayText={assetNoLinkedGatewayText}
    //       defaultText={assetDefaultText}
    //     />,
    // },
  ];
};
