import { FC } from 'react';
import DashboardOccupationLoadingTemplate from './loading';
import DashboardOccupationDefaultTemplate from './default';
import DashboardOccupationErrorTemplate from './error';
import { OccupationDashboardProps } from 'components/pages/DashboardPage/resources/models';

const DashboardOccupationTemplate: FC<OccupationDashboardProps> = (props) => {
  const { isError, isLoading } = props;

  if (isError) {
    return <DashboardOccupationErrorTemplate {...props} />
  }

  if (isLoading) {
    return <DashboardOccupationLoadingTemplate />
  }

  return (
    <DashboardOccupationDefaultTemplate {...props} />
  )
}

export default DashboardOccupationTemplate;