import { RegisterUserFormValuesType } from "components/organisms/RegisterUserForm";
import { useState } from "react";
import { registerUser } from "services/api/users";

export const useRegisterUser = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [registering, setRegistering] = useState<boolean>(true);
  const alignment: string = registering ? 'RegisterUserPage--alignTop' : '';

  const handleOnForm = async (values: RegisterUserFormValuesType): Promise<void> => {
    try {
      setLoading(true);
      await registerUser(values);
      setRegistering(false);
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };


  return {
    alignment,
    handleOnForm,
    isLoading: loading,
    isRegistering: registering,
  }
}