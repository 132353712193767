import { FC, useEffect, useState } from 'react';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Input, Form, Select, DatePicker, Button, Modal, message, Typography } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { DATE_TIME_FORMAT } from 'constants/date';
import { Invitation } from 'models/assets.model';
import { Device } from 'models/devices.model';
import { createAnonymousInvitation, getAssetDevices } from 'services/api/assets';
import { COLORS } from 'constants/colors';
import { getSensorLabel } from '../../resources/utils';
import { createAccess } from './create-access';
import { disabledDate, disabledDateTime } from './disabled-date';
import './styles.scss';

export interface AccessesForm {
  description: string;
  accesses: string[];
  datePicker: Moment[];
}

interface SelectedAccess {
  label: string;
  value: string;
}

interface CreateInvitationsModalProps {
  assetId: string;
  visible: boolean;
  onClose: () => void;
}

const FORM_ID = 'form_create_invitation';

const CreateInvitationModal: FC<CreateInvitationsModalProps> = ({ assetId, visible, onClose }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [locks, setLocks] = useState<Device[]>();

  useEffect(() => {
    getAssetDevices(assetId, 'lock').then((response) => {
      if (!!response) {
        const devices = response.gateways.flatMap(({ devices }) => devices);
        setLocks(devices);
      }
    });
  }, [assetId]);

  const confirmInvitation = (response: Invitation) => {
    Modal.confirm({
      title: t('invitation_created'),
      icon: <CheckCircleTwoTone twoToneColor={COLORS.success} />,
      content: (
        <>
          <p>
            <span className='Text__secondary'>
              <b>{t('description')}: </b>
            </span>
            {response.attributes.alias}
          </p>
          <p>
            <span className='Text__secondary'>
              <b>{t('invitation_link')}: </b>
            </span>
            <Typography.Text keyboard>{response.attributes.url}</Typography.Text>
          </p>
        </>
      ),
      onOk: () => {
        navigator.clipboard.writeText(response.attributes.url);
        message.success(`${t('copied')}`);
      },
      okText: t('copy_and_close'),
      okCancel: false,
    });
  };

  const handleOnFinish = async ({ description, accesses, datePicker }: AccessesForm) => {
    setLoading(true);
    const data = createAccess(assetId, { description, accesses, datePicker }, locks);

    try {
      const anonymousAccessToAsset = await createAnonymousInvitation(data);
      if (!!anonymousAccessToAsset) {
        onClose();
        confirmInvitation(anonymousAccessToAsset);
        form.resetFields();
      }
    } finally {
      setLoading(false);
    }
  };

  const getFilteredAccessSensors = (locks: Device[]): SelectedAccess[] => {
    const accesses = locks.map(({ sensors, device_id }) => {
      return sensors.filter(({ item_name }) => item_name).map(({ name, room, sensor_id }) => {
        const label = getSensorLabel({ name, room });
        const value = `${device_id}::::${sensor_id}`;
        return { label, value }
      })
    }).flat();
    return accesses;
  }

  return (
    <Modal
      className='CreateInvitationModal'
      title={t('ANONYMOUS_ACCESS.create_a_invitation')}
      visible={visible}
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      footer={[
        <Button
          key='back'
          onClick={() => {
            onClose();
            form.resetFields();
          }}
        >
          {t('close')}
        </Button>,
        <Button form={FORM_ID} key='submit' type='primary' htmlType='submit' loading={loading}>
          {t('send')}
        </Button>,
      ]}
    >
      <Form<AccessesForm> form={form} id={FORM_ID} onFinish={handleOnFinish} layout='vertical'>
        <Form.Item name='description' label={`${t('description')}:`} rules={[{ required: true, message: t('field_required') }]} hasFeedback>
          <Input placeholder={t('description_of_the_invitation')} />
        </Form.Item>
        <Form.Item
          name='accesses'
          label={`${t('CREATE_INVITATION.what_accesses_do_you_want_to_share')}:`}
          rules={[{ required: true, message: t('field_required') }]}
          hasFeedback
        >
          <Select
            allowClear
            mode='tags'
            options={locks && getFilteredAccessSensors(locks)}
            placeholder={t('select_the_access')}
          />
        </Form.Item>
        <Form.Item
          name='datePicker'
          label={`${t('CREATE_INVITATION.select_the_start_and_end_date_and_hour')}:`}
          rules={[{ required: true, message: t('field_required') }]}
          hasFeedback
        >
          <DatePicker.RangePicker
            disabledDate={disabledDate}
            disabledTime={disabledDateTime}
            format={DATE_TIME_FORMAT}
            showSecond={false}
            showTime
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateInvitationModal;
