import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string) => {
  return `${host}/devices/toggle/lock`;
};

const OPEN_VALUE = '1';

export const OpenAccessDevice = async ({ token, host, accessSensorId }: { token: string; host: string; accessSensorId: string }) => {
  try {
    const url = getUrl(host);
    const options: AxiosRequestConfig = {
      url,
      method: 'PATCH',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data: { access_sensor_id: accessSensorId, value: OPEN_VALUE } },
    };

    (await axios(options)) as AxiosResponse<void>;
  } catch (error) {
    throw getErrorFormat(error);
  }
};
