import { ReactNode, VFC } from 'react';
import DrawerAtom from 'components/atoms/DrawerAtom';
import { Camera } from 'core/domain/assets/models/camera';
import { useTranslation } from 'react-i18next';
import './styles.scss';

interface CameraItemProps {
  title: string;
  children: ReactNode;
}

const CameraItem: VFC<CameraItemProps> = ({ title, children }) => {
  return (
    <p>
      <span className={'CameraItem__title'}>{title}</span>: {children}
    </p>
  );
};

interface CameraDrawerProps {
  item: Camera;
  onClose: () => void;
  visible: boolean;
}

const CameraDrawer: VFC<CameraDrawerProps> = ({
  item: { name, remote, local, vendor, model, deviceSerial, enabled, comments },
  onClose,
  visible,
}) => {
  const { t } = useTranslation();

  return (
    <DrawerAtom title={`${t('camera')}: ${name}`} placement={'right'} onClose={onClose} open={visible}>
      <CameraItem title={t('name')}>{name}</CameraItem>
      <CameraItem title={t('remote')}>{remote || t('not_reported')}</CameraItem>
      <CameraItem title={t('local')}>{local || t('not_reported')}</CameraItem>
      <CameraItem title={t('vendor')}>{vendor}</CameraItem>
      <CameraItem title={t('model')}>{model}</CameraItem>
      <CameraItem title={t('serialNumber')}>{deviceSerial || t('not_reported')}</CameraItem>
      <CameraItem title={t('status')}>{enabled ? t('active') : t('inactive')}</CameraItem>
      <CameraItem title={t('comments')}>{comments || t('not_reported')}</CameraItem>
    </DrawerAtom>
  );
};

export default CameraDrawer;
