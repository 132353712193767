import api from 'services/api';
import { checkLegalDocumentationPath } from 'core/endspoints/clients';
import { LegalDocumentationCheckedModel } from '../models/clientEntityModel';
import { LegalDocumentationCheckedPayloadModel } from '../models/clientPayloadModel';
import { payloadToLegalDocumentationChecked } from '../mappers/payloadToLegalDocumentationChecked';
import { LegalDocumentationCheckedEntity } from '../entities/legalDocumentationCheckedEntity';

export const CheckLegalDocumentation = async (): Promise<LegalDocumentationCheckedModel> => {
  api.defaults.headers.common['Accept-version'] = '1';
  const url = checkLegalDocumentationPath();

  try {
    const docuData = await api.get<LegalDocumentationCheckedPayloadModel>(url);
    const mappedData = docuData?.data && payloadToLegalDocumentationChecked(docuData.data);
    return mappedData && LegalDocumentationCheckedEntity(mappedData);
  } catch (error: any) {
    throw new Error(error);
  }
};
