import { FC } from 'react';
import AsChart from 'components/atoms/AsChart';
import { configuration } from './resources/config';
import { OccupationDashboardProps } from 'components/pages/DashboardPage/resources/models';
import './styles.scss';

const DashboardOccupationDefaultTemplate: FC<OccupationDashboardProps> = ({
  assetsLinked,
  assetsNotLinked,
  literals,
}) => {
  const { title, titleTimePeriod, occupied, unoccupied } = literals
  const data = { ...configuration.data, labels: [unoccupied, occupied] };
  data.datasets[0].data = [assetsNotLinked, assetsLinked];

  return (
    <div className='OccupationTemplate__container'>
      <div className='OccupationTemplate__header'>
        <p className='OccupationTemplate__title'>{title}</p>
        <p className='OccupationTemplate__subtitle'>{titleTimePeriod}</p>
      </div>
      <div className='OccupationTemplate__chart-container'>
        <div className='OccupationTemplate__chart-content'>
          <AsChart
            configuration={{ ...configuration, data }}
            canvasWidth={'240'}
            canvasHeight={'240'}
          />
        </div>
      </div>
    </div>
  )
};

export default DashboardOccupationDefaultTemplate;
