import {
  InfoCircleOutlined,
  InfoCircleFilled,
  CalendarOutlined,
  TeamOutlined,
  LockOutlined,
  LineChartOutlined,
  TabletOutlined,
  ContainerOutlined,
  CustomerServiceOutlined,
  AppstoreOutlined,
  VideoCameraOutlined,
  PlusOutlined,
  StarOutlined,
  LinkOutlined,
  RobotOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  QuestionCircleTwoTone,
  CloudUploadOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  EllipsisOutlined,
  BarChartOutlined,
  ProfileTwoTone,
  ProjectTwoTone,
  BgColorsOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
  CheckCircleOutlined,
  FundProjectionScreenOutlined,
  QrcodeOutlined,
  SearchOutlined,
  SettingFilled,
  HomeOutlined,
  CameraOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import Empty from 'assets/svg/icon/Empty';
import Intercom from 'assets/svg/icon/Intercom';
import Maintenance from 'assets/svg/icon/Maintenance';
import Home from 'assets/svg/icon/Home';
import List from 'assets/svg/icon/List';
import Users from 'assets/svg/icon/Users';
import Business from 'assets/svg/icon/Business';
import Communities from 'assets/svg/icon/Communities';
import Flag from 'assets/svg/icon/Flag';
import Folder from 'assets/svg/icon/Folder';
import Invitation from 'assets/svg/icon/Invitation';
import EmptyBooking from 'assets/svg/icon/EmptyBooking';
import DoorOpen from 'assets/svg/icon/DoorOpen';
import EmptyAccess from 'assets/svg/icon/EmptyAccess';
import Gateway from 'assets/svg/icon/Gateway';

export const Icon = {
  VideoCamera: VideoCameraOutlined,
  InfoCircle: InfoCircleOutlined,
  InfoCircleFilled: InfoCircleFilled,
  Calendar: CalendarOutlined,
  Camera: CameraOutlined,
  Customization: EditOutlined,
  Team: TeamOutlined,
  Lock: LockOutlined,
  LineChart: LineChartOutlined,
  Tablet: TabletOutlined,
  Container: ContainerOutlined,
  CustomerService: CustomerServiceOutlined,
  StarOutlined: StarOutlined,
  AppsStore: AppstoreOutlined,
  Plus: PlusOutlined,
  Link: LinkOutlined,
  LinkNewTab: ExportOutlined,
  Robot: RobotOutlined,
  Check: CheckCircleTwoTone,
  Close: CloseCircleTwoTone,
  Question: QuestionCircleTwoTone,
  Cloud: CloudUploadOutlined,
  Exclamation: ExclamationCircleOutlined,
  PDF: FilePdfOutlined,
  Elipsis: EllipsisOutlined,
  ListColor: ProfileTwoTone,
  BarChart: BarChartOutlined,
  BarChartColor: ProjectTwoTone,
  ColorPicker: BgColorsOutlined,
  Empty,
  Intercom,
  Maintenance,
  Delete: DeleteOutlined,
  Show: EyeOutlined,
  CheckIn: CheckCircleOutlined,
  DigitalSignage: FundProjectionScreenOutlined,
  Provisioned: LinkOutlined,
  Home,
  List,
  Users,
  Business,
  Communities,
  Flag,
  Folder,
  Invitation,
  EmptyBooking,
  DoorOpen,
  EmptyAccess,
  QR: QrcodeOutlined,
  Search: SearchOutlined,
  Gateway,
  Setting: SettingFilled,
  Asset: HomeOutlined,
};
