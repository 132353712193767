import { FC } from 'react';
import { IMAGES } from 'constants/images';
import './styles.scss';

interface Props {
  title?: string;
  description?: string;
  isActiveInfo: boolean;
}

const WebappPreview: FC<Props> = ({ title, description, isActiveInfo }) => {
  return (
    <div className={'WebAppPreview'}>
      <div className={'WebAppPreview__container'}>
        <div className={'WebAppPreview__header'}>
          <div className={'WebAppPreview__header-browser'}>
            <img src={IMAGES.previewWebApp.browser} alt={''} />
          </div>
          <div className={'WebAppPreview__header-image'}>
            <img src={IMAGES.previewWebApp.backgroundHeader} alt={''} />
          </div>
        </div>
        <div className={'WebAppPreview__widget'}>
          <div className={'WebAppPreview__widget-header'}>
            <div className={'WebAppPreview__widget-header-info'} />
            <img src={IMAGES.previewWebApp.close} alt={''} />
          </div>
          {isActiveInfo && (
            <div className={'WebAppPreview__widget-custom-info'}>
              <div>
                <img src={IMAGES.previewWebApp.info} alt='' />
              </div>
              <div className={'WebAppPreview__widget-custom-info-body'}>
                <div className={'WebAppPreview__widget-custom-info-title'}>{title}</div>
                <div className={'WebAppPreview__widget-custom-info-text'}>{description}</div>
              </div>
            </div>
          )}
          <div className={'WebAppPreview__widget-body'}>
            <div className={'WebAppPreview__widget-title'}>Puerta Cerrada</div>
            <div className={'WebAppPreview__widget-text'}>Desliza para abrir</div>
          </div>
          <div className={'WebAppPreview__widget-slider'}>
            <img src={IMAGES.previewWebApp.slider} alt={''} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebappPreview;