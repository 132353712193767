import { useTranslation } from 'react-i18next';
import { Menu, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { AccessListModel } from '../../resources/useAccess';
import ContextualMenu from 'components/atoms/ContextualMenu';
import { SensorWithPasswordsModel } from 'core/domain/sensors/models/sensorEntityModels';

interface AccessColumnParams {
  onClickPasswordSettings: (sensor: SensorWithPasswordsModel) => void;
  onClickInstructionsSettings: (sensor: SensorWithPasswordsModel) => void;
}

export const AccessColumn = ({
  onClickPasswordSettings,
  onClickInstructionsSettings,
}: AccessColumnParams): ColumnType<AccessListModel>[] => {
  const { t } = useTranslation();

  return [
    {
      title: t('door'),
      key: 'door',
      width: '35%',
      render: (text, { deviceName }) => <Typography.Text>{!!deviceName ? deviceName : t('not_reported')}</Typography.Text>,
    },
    {
      title: t('name'),
      key: 'name',
      width: '15%',
      render: (text, { parameterName }) => <Typography.Text>{!!parameterName ? parameterName : t('not_reported')}</Typography.Text>,
    },
    {
      title: t('room'),
      key: 'room',
      width: '15%',
      render: (text, { parameterRoom }) => <Typography.Text>{!!parameterRoom ? parameterRoom : t('not_reported')}</Typography.Text>,
    },
    {
      title: `${t('type')}`,
      key: 'deviceType',
      width: '30%',
      render: (text, { deviceType }) => <Typography.Text>{!!deviceType ? deviceType : t('not_reported')}</Typography.Text>,
    },
    {
      title: t(''),
      key: 'actions',
      width: '5%',
      render: (text, record) => {
        return (
          <ContextualMenu placement={'topRight'} menu={
            <Menu>
              <Menu.Item
                key={'onClickPasswordSettings'}
                onClick={() => onClickPasswordSettings(record)}
              >
                {t('_ASSET_ACCESS_SETTINGS_MENU_PASSWORD')}
              </Menu.Item>
              <Menu.Item
                key={'onClickInstructionsSettings'}
                onClick={() => onClickInstructionsSettings(record)}
              >
                {t('_ASSET_ACCESS_SETTINGS_MENU_INSTRUCTIONS')}
              </Menu.Item>
            </Menu>
          } />
        );
      },
    },
  ];
};
