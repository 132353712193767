import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  DeviceHelpDataPayloadResponse,
  EditDeviceHelpParams,
} from '../models';
import { deviceHelpDataModelToPayload } from '../mappers';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = (host: string) => {
  return `${host}/devices/help`;
};

export const UpdateDeviceHelpData = async ({
  description,
  title,
  deviceId,
  gatewayId,
  host,
  token
}: EditDeviceHelpParams): Promise<void> => {
  try {
    const url = getUrl(host);
    const data = deviceHelpDataModelToPayload({
      title,
      description,
      gatewayId,
      deviceId,
    });
    const options: AxiosRequestConfig = {
      url,
      method: 'PATCH',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
      data: { data },
    };

    await axios(options) as AxiosResponse<DeviceHelpDataPayloadResponse>;
  } catch (error) {
    throw getErrorFormat(error);
  }
}