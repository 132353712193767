import { useState, useEffect } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClientCustomDesignAssetsManagerModel } from 'core/domain/client/models/clientEntityModel';
import { useClientCRUD } from 'hooks/useClient/useClientCRUD';
import { useProfileUser } from 'hooks/useProfileUser';
import {
  ClientCustomizationFormValues,
  ClientCustomizationFormValuesNames,
  ClientCustomizationInputValue,
  ClientCustomizationTranslations,
} from './models';

enum CustomizationFormValueTranslation {
  LOGO = '_CUSTOMIZATION_FORM_VALUE_LOGO',
  LOGO_REQUIREMENTS = '_CUSTOMIZATION_FORM_VALUE_LOGO_REQUIREMENTS',
  BACKGROUND_COLOR = '_CUSTOMIZATION_FORM_VALUE_BACKGROUND_COLOR',
  INFO = '_CUSTOMIZATION_FORM_INFO',
  CANCEL = '_CUSTOMIZATION_FORM_CANCEL',
  PUBLISH = '_CUSTOMIZATION_FORM_PUBLISH',
  PUBLISHED = '_CUSTOMIZATION_PUBLISHED',
}

const validateBackgroundColorFormat = (background: string): boolean => {
  const isValidHexFormat = /^#([0-9a-f]{6}){1,2}$/i;
  return isValidHexFormat.test(background);
};

export const useClientCustomization = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ClientCustomizationFormValues>();
  const [initialFormValues, setInitialFormValues] = useState({} as ClientCustomDesignAssetsManagerModel);
  const [formValues, setFormValues] = useState({} as ClientCustomizationFormValues);
  const [initialPreViewValues, setInitialPreviewValues] = useState({} as ClientCustomizationFormValues);
  const [previewValues, setPreviewValues] = useState({} as ClientCustomizationFormValues);
  const [areThereValidChanges, setAreThereValidChanges] = useState<boolean>(false);
  const [areFormValuesCanceled, setAreFormValuesCanceled] = useState<boolean>(false);
  const { data: clientData, loading, updating, updateClient } = useClientCRUD();
  const { onRefreshProfile } = useProfileUser();

  const clientUpdateTranslations: ClientCustomizationTranslations = {
    logo: t(CustomizationFormValueTranslation.LOGO),
    logoRequirements: t(CustomizationFormValueTranslation.LOGO_REQUIREMENTS),
    backgroundColor: t(CustomizationFormValueTranslation.BACKGROUND_COLOR),
    info: t(CustomizationFormValueTranslation.INFO),
    cancel: t(CustomizationFormValueTranslation.CANCEL),
    publish: t(CustomizationFormValueTranslation.PUBLISH),
    published: t(CustomizationFormValueTranslation.PUBLISHED),
  };

  const getInitialFormValues = ({ logo, backgroundColor, isDefault }: ClientCustomDesignAssetsManagerModel) => {
    setFormValues({ logo, backgroundColor });
    setInitialFormValues({ logo, backgroundColor, isDefault });
  };

  const getInitialPreviewValues = ({ logo, backgroundColor }: ClientCustomizationFormValues) => {
    setPreviewValues({ logo, backgroundColor });
    setInitialPreviewValues({ logo, backgroundColor });
  };

  const getInitialValues = ({ logo, backgroundColor, isDefault }: ClientCustomDesignAssetsManagerModel) => {
    getInitialFormValues({ logo, backgroundColor, isDefault });
    getInitialPreviewValues({ logo, backgroundColor });
  };

  useEffect(() => {
    clientData && clientData.lookAndFeel && getInitialValues(clientData.lookAndFeel.assetsManager);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData]);

  const onChangeLogo = (logo: string) => {
    setPreviewValues({ ...previewValues, logo });
    setFormValues({ ...formValues, logo });
  };

  const onChangeBackgroundColor = (backgroundColor: string) => {
    const previewBackgroundColor = backgroundColor === '' ? initialPreViewValues.backgroundColor : backgroundColor;
    setPreviewValues({ ...previewValues, backgroundColor: previewBackgroundColor });
    setFormValues({ ...formValues, backgroundColor });
  };

  const onCancelFormValues = async () => {
    const { logo, backgroundColor } = initialFormValues;
    setFormValues({ logo, backgroundColor });
  };

  const onCancelPreViewValues = async () => {
    const { logo, backgroundColor } = initialPreViewValues;
    setPreviewValues({ logo, backgroundColor });
  };

  const onCancel = async () => {
    setAreFormValuesCanceled(true);
    try {
      await onCancelFormValues();
      await onCancelPreViewValues();
    } finally {
      setAreThereValidChanges(false);
      setAreFormValuesCanceled(false);
    }
  };

  const checkIfLogoIsNotEmptyAndDefault = (value: string) => {
    const isEmptyDefaultValue = value === '' && initialFormValues.isDefault;
    return !isEmptyDefaultValue;
  };

  const updateFormValuesManager = {
    [ClientCustomizationFormValuesNames.LOGO]: (value: string) => onChangeLogo(value),
    [ClientCustomizationFormValuesNames.BACKGROUND_COLOR]: (value: string) => onChangeBackgroundColor(value),
  };

  const checkForFormValuesChangesManager = {
    [ClientCustomizationFormValuesNames.LOGO]: (value: string): boolean =>
      value !== initialFormValues.logo &&
      checkIfLogoIsNotEmptyAndDefault(value) &&
      validateBackgroundColorFormat(formValues.backgroundColor),
    [ClientCustomizationFormValuesNames.BACKGROUND_COLOR]: (value: string): boolean =>
      value !== initialFormValues.backgroundColor && validateBackgroundColorFormat(value),
  };

  const onChangeMade = ({ label, value }: ClientCustomizationInputValue) => {
    updateFormValuesManager[label](value);
    const hasFormValueChanged = checkForFormValuesChangesManager[label](value);
    setAreThereValidChanges(hasFormValueChanged);
  };

  const onPublish = async () => {
    const logo = formValues.logo || null;
    const assetsManager = { ...formValues, logo };
    const lookAndFeel = { ...clientData.lookAndFeel, assetsManager };
    const client = { ...clientData, lookAndFeel };
    const textMessage = clientUpdateTranslations.published;

    try {
      await updateClient({ client, textMessage });
      onRefreshProfile();
    } catch (error) {
      console.warn(error);
    }
  };

  return {
    initialFormValues,
    customizationFormValues: formValues,
    customizationPreviewValues: previewValues,
    translations: clientUpdateTranslations,
    form,
    Form,
    onChangeMade,
    onCancel,
    onPublish,
    loading,
    updating,
    areThereValidChanges,
    formValuesCanceled: areFormValuesCanceled,
  };
};
