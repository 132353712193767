import { MouseEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getAssetPathById } from 'components/pages/App/routes/assets/config';
import { useTranslation } from 'react-i18next';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { getProjectTabWithPaginationPath } from 'components/pages/App/routes/projects/config';
import { getProjectTabInfoWithPagination } from 'core/domain/project/repositories/project';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';

export const useProjectAssets = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { t } = useTranslation();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [assets, setAssets] = useState<ProjectAssetModel[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState<number>(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [totalPages, setTotalPages] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [searchQuery, setSearchQuery] = useState('');

  const assetOccupationTranslations = {
    assetOccupied: t('_ASSET_OCCUPIED'),
    assetNotOccupied: t('_ASSET_NOT_OCCUPIED'),
  };

  const assetGatewayConnectionStatusTranslations = {
    connected: t('_ASSET_GATEWAY_CONNECTED'),
    disconnected: t('_ASSET_GATEWAY_DISCONNECTED'),
    noLinkedGateways: t('_ASSET_GATEWAY_NO_LINKED'),
    defaultText: t('not_reported'),
  };

  const getProjectAssetsWithPagination = async ({ page, size, search }: { page: number; size: number; search?: string }) => {
    setIsLoading(true);
    try {
      const response = await getProjectTabInfoWithPagination({ projectId, page, size, search });
      if (response) {
        setAssets(response.data);
        setTotalPages(response.meta.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectAsset = (asset: ProjectAssetModel) => {
    history.push(getAssetPathById(asset.id));
  };

  const onChangePage = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    setPageSize(pageSize!);
    history.push(getProjectTabWithPaginationPath({ projectId, page: String(page), size: String(pageSize!) }));
  };

  const onSearch = (value: string) => {
    setSearchQuery(value);
    setCurrentPage(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  };

  const onOpenLink = (assetId: string, event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const url = `${window.location.origin}/admin/assets/${assetId}/start`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    const newPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const newSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    setCurrentPage(Number(newPage));
    setPageSize(Number(newSize));
  }, [page, size]);

  useEffect(() => {
    getProjectAssetsWithPagination({
      page: Number(page) || Number(TABLE_PAGINATION_PAGE_DEFAULT),
      size: Number(size) || Number(TABLE_PAGINATION_SIZE_DEFAULT),
      search: searchQuery,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, page, size, searchQuery]);

  return {
    assets,
    totalPages,
    currentPage,
    pageSize,
    assetOccupationTranslations,
    assetGatewayConnectionStatusTranslations,
    onSelectAsset,
    onChangePage,
    onSearch,
    onOpenLink,
    loadingAssets: isLoading,
  };
};
