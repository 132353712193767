import { FC } from 'react';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import ModalAtom, { ModalAtomProps } from 'components/atoms/ModalAtom';
import './styles.scss';

export interface OpenOfficeAccessDeviceModalLiteralsModel {
  title: string;
  text: string;
  description: string;
  primaryButtonText: string;
  secondaryButtonText: string;
}

interface OpenOfficeAccessDeviceModalProps extends ModalAtomProps {
  literals: OpenOfficeAccessDeviceModalLiteralsModel,
}

const OpenOfficeAccessDeviceModal: FC<OpenOfficeAccessDeviceModalProps> = ({ onCancel, onOk, title, literals, ...props }) => {
  const { text, description, primaryButtonText, secondaryButtonText } = literals;

  return (
    <ModalAtom
      {...props}
      title={title}
      onCancel={onCancel}
      destroyOnClose
      footer={[
        <DefaultButton key={'cancel'} onClick={onCancel} title={secondaryButtonText} />,
        <PrimaryButton key={'confirm'} onClick={onOk} title={primaryButtonText} />
      ]}
    >
      <div className={'OpenOfficeAccessDeviceModal'}>
        <p className={'OpenOfficeAccessDeviceModal__question'}>{text}</p>
        <p>{description}</p>
      </div>
    </ModalAtom>
  );
};

export default OpenOfficeAccessDeviceModal;
