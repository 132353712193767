import { FC } from 'react';
import DashboardSupportTicketsDefaultTemplate from './default';
import DashboardSupportTicketsErrorTemplate from './error';
import DashboardSupportTicketsLoadingTemplate from './loading';
import { SupportTicketsDashboardProps } from 'components/pages/DashboardPage/resources/models';

const DashboardSupportTicketsTemplate: FC<SupportTicketsDashboardProps> = (props) => {
  const { isError, isLoading } = props;

  if (isError) {
    return <DashboardSupportTicketsErrorTemplate {...props} />
  }

  if (isLoading) {
    return <DashboardSupportTicketsLoadingTemplate />
  }

  return <DashboardSupportTicketsDefaultTemplate {...props} />
}

export default DashboardSupportTicketsTemplate;