import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GetClientsList } from 'core/domain/client/repositories/getClientsList';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { GetProjectsListByClientId } from 'core/domain/project/repositories/projectsCRUD';
import { ProjectListResponseDataModel } from 'core/domain/project/model/projectModel';
import { getCommonAreasV2Path, getCommonAreasBookingsListPath } from 'components/pages/App/routes/commonAreas/config';
import UserProfileContext, { UserProfileContextState } from 'context/UserProfileContext';

export interface ClientAndProjectCustomData {
  value: string;
  label: string;
}

enum RouteSegmentType {
  DEFAULT_SEGMENT = 3,
  CLIENT_AND_PROJECT_SEGMENT = 8,
  COMMON_AREA_DETAIL_SEGMENT = 9,
  COMMON_AREA_UPDATE_SEGMENT = 10,
}

export const useCommonAreasClientAndProjectSelector = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userProfile } = useContext<UserProfileContextState>(UserProfileContext);
  const [isLoadingClientsList, setIsLoadingClientsList] = useState<boolean>(false);
  const [isLoadingProjectsList, setIsLoadingProjectsList] = useState<boolean>(false);
  const [clientsList, setClientsList] = useState<ClientAndProjectCustomData[]>([]);
  const [currentClient, setCurrentClient] = useState<string | null>(null);
  const [currentProject, setCurrentProject] = useState<string | null>(null);
  const [projectsList, setProjectsList] = useState<ClientAndProjectCustomData[]>([]);
  const [showEmptyMessage, setShowEmptyMessage] = useState<boolean>(true);

  const commonAreasV2Clients = process.env.REACT_APP_COMMON_AREAS_V2_CLIENT_ID_LIST?.split(',');
  const isCommonAreasV2Client = commonAreasV2Clients?.some((item) => item === currentClient);

  const title: string = t('_COMMON_AREAS_TITLE');
  const clientPlaceholderText: string = t('_COMMON_AREAS_CLIENT_SELECTOR_PLACEHOLDER');
  const projectPlaceholderText: string = t('_COMMON_AREAS_PROJECT_SELECTOR_PLACEHOLDER');
  const emptyText: string = t('_COMMON_AREAS_WITH_NO_CLIENT_AND_PROJECT_EMPTY_TEXT');

  const onSelectClient = (value: string) => {
    setCurrentClient(value);
    setShowEmptyMessage(true);
    setCurrentProject(null);
    setProjectsList([]);
  };

  const navigateToBookingsList = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
    const bookingsListRoute = getCommonAreasBookingsListPath({ clientId, projectId });
    history.push(bookingsListRoute);
  };

  const navigateToCommonAreasV2 = ({ clientId, projectId }: { clientId: string; projectId: string }) => {
    const commonAreasV2Route = getCommonAreasV2Path({ clientId, projectId });
    history.push(commonAreasV2Route);
  };

  const onSelectProject = (value: string) => {
    setCurrentProject(value);
    setShowEmptyMessage(false);

    if (!!currentClient) {
      isCommonAreasV2Client
        ? navigateToCommonAreasV2({ clientId: currentClient, projectId: value })
        : navigateToBookingsList({ clientId: currentClient, projectId: value });
    }
  };

  const transformClientsListData = (clients: ClientModel[]): ClientAndProjectCustomData[] => {
    return clients.map(({ id, name }) => ({ value: id, label: name }));
  };

  const getCurrentClient = async (clients: ClientAndProjectCustomData[]) => {
    const splitRoute = window.location.pathname.split('/');
    const index = splitRoute.length;
    if (!!userProfile?.client?.id && index < 4) {
      const selectedClient = clients.find(({ value }) => userProfile.client?.id === value);
      selectedClient && setCurrentClient(selectedClient.value);
    }
  };

  const getClientsList = async () => {
    setIsLoadingClientsList(true);
    try {
      const clients = await GetClientsList();
      if (!!clients?.length) {
        const transformedClients = transformClientsListData(clients);
        await getCurrentClient(transformedClients);
        setClientsList(transformedClients);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingClientsList(false);
    }
  };

  const transformProjectsListData = (clients: ProjectListResponseDataModel[]): ClientAndProjectCustomData[] => {
    return clients.map(({ id, name }) => ({ value: id, label: name }));
  };

  const updateCurrentProjectAndNavigateToDetail = (projectId: string) => {
    setCurrentProject(projectId);

    if (!!currentClient) {
      isCommonAreasV2Client
        ? navigateToCommonAreasV2({ clientId: currentClient, projectId })
        : navigateToBookingsList({ clientId: currentClient, projectId });
    }
  };

  const getProjectsListByClientId = async (clientId: string) => {
    setIsLoadingProjectsList(true);
    try {
      const response = await GetProjectsListByClientId(clientId);
      if (!!response?.data?.length) {
        const transformedProjects = transformProjectsListData(response.data);
        setProjectsList(transformedProjects);
        response.data.length === 1 && updateCurrentProjectAndNavigateToDetail(transformedProjects[0].value);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoadingProjectsList(false);
    }
  };

  const resetInitialValues = () => {
    setShowEmptyMessage(true);
    setCurrentClient(null);
    setCurrentProject(null);
  };

  const updateValues = (client: string, project: string) => {
    setShowEmptyMessage(false);
    setCurrentClient(client);
    setCurrentProject(project);
  };

  const routeManager = {
    [RouteSegmentType.DEFAULT_SEGMENT]: resetInitialValues,
    [RouteSegmentType.CLIENT_AND_PROJECT_SEGMENT]: (client: string, project: string) => updateValues(client, project),
    [RouteSegmentType.COMMON_AREA_DETAIL_SEGMENT]: (client: string, project: string) => updateValues(client, project),
    [RouteSegmentType.COMMON_AREA_UPDATE_SEGMENT]: (client: string, project: string) => updateValues(client, project),
  };

  useEffect(() => {
    const splitRoute = window.location.pathname.split('/');
    const index = splitRoute.length;
    routeManager[index as RouteSegmentType](splitRoute[4], splitRoute[6]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    !!currentClient && getProjectsListByClientId(currentClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  useEffect(() => {
    !!userProfile && getClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  return {
    title,
    currentClient,
    currentProject,
    clientPlaceholderText,
    projectPlaceholderText,
    emptyText,
    clientsList,
    projectsList,
    onSelectClient,
    onSelectProject,
    loadingClientsList: isLoadingClientsList,
    loadingProjectsList: isLoadingProjectsList,
    showEmptyMessage,
  };
};
