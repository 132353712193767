import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUserSession } from 'hooks/useUserSession';
import { checkIfHexColorIsLight } from 'utils/color';
import { COLORS } from 'constants/colors';
import { ROUTE_PATH_LOGIN } from 'components/pages/App/routes';
import { ROUTE_PATH_ADMIN } from 'components/pages/App/configRoutes';
import { ROUTE_PATH_DASHBOARD } from 'components/pages/App/routes/dashboard/config';
import { TablePaginationSearchValue } from 'constants/table';
import { CurrentUserClientModel } from 'core/domain/users/models/userModel';
import { keysMenuToPathManager } from './config';
import UserProfileContext, { UserProfileContextState } from 'context/UserProfileContext';
import { useProfileUser } from 'hooks/useProfileUser';

export interface HeaderCustomization {
  logo: string;
  backgroundColor: string;
  searchIconColor: string;
  avatarColor: string;
}

export const useAdmin = () => {
  const history = useHistory();
  const { checkIfUserIsLoggedIn } = useUserSession();
  const isLoggedIn = checkIfUserIsLoggedIn();
  const { userProfile, userProfileLoading } = useContext<UserProfileContextState>(UserProfileContext);
  const { onRefreshProfile } = useProfileUser();
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [pathname, setPathname] = useState<string>('')
  const [isMenuIconVisible, setIsMenuIconVisible] = useState<boolean>(false);
  const [headerCustomization, setHeaderCustomization] = useState({} as HeaderCustomization);
  const logoRoute = ROUTE_PATH_DASHBOARD;
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);

  const getHeaderCustomization = (client: CurrentUserClientModel) => {
    const { assetsManagerLogo: logo, assetsManagerBackgroundColor: backgroundColor } = client;
    const isLightBackgroundColor = checkIfHexColorIsLight(backgroundColor);
    const searchIconColor = isLightBackgroundColor ? COLORS.gray : COLORS.white;
    const avatarColor = isLightBackgroundColor ? COLORS.white : backgroundColor;
    setHeaderCustomization({ logo, backgroundColor, searchIconColor, avatarColor })
  }

  const onReachLayoutBreakpoint = (broken: boolean) => {
    setIsMenuIconVisible(broken);
  };

  const onClickMenuIcon = () => {
    setIsDrawerVisible(true);
  }

  const onCloseMenu = () => {
    setIsDrawerVisible(false);
  }

  const onClickMenuItem = (key: string) => {
    const path = keysMenuToPathManager[key];
    setIsDrawerVisible(false);
    setPathname(path);
    history.push(path);
  };

  const onActiveMenuItem = (): string => {
    const basePathname = window.location.pathname.split('/').splice(0, 3).join('/');
    const basePathNameWithoutQueries = basePathname.split('?').slice(0, 1).join();
    return basePathNameWithoutQueries;
  };

  useEffect(() => {
    !isLoggedIn && history.push(ROUTE_PATH_LOGIN);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn])

  useEffect(() => {
    (pathname === '/' || pathname === ROUTE_PATH_ADMIN) && history.push(ROUTE_PATH_DASHBOARD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    const url = window.location.pathname
    const cleanUrl = url.endsWith('/') && url.includes('admin') ? url.slice(0, -1) : url;
    const filteredUrl = !!page && !!size ? `${cleanUrl}?page=${page}&size=${size}` : cleanUrl;
    setPathname(filteredUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, window.location.pathname])

  useEffect(() => {
    onRefreshProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    !!userProfile?.client && getHeaderCustomization(userProfile.client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  return {
    pathname,
    headerCustomization,
    logoRoute,
    onClickMenuItem,
    onActiveMenuItem,
    onReachLayoutBreakpoint,
    onCloseMenu,
    onClickMenuIcon,
    userProfileLoading,
    visibleDrawer: isDrawerVisible,
    visibleMenuIcon: isMenuIconVisible,
  };
};
