import {
  CustomActiveGatewaysStatisticsModel,
  SupportTicketsDashboardProps,
  ActiveGatewaysDashboardProps,
  SupportTicketsLiteralsTypes,
  OccupationDashboardProps,
  OccupationLiteralsTypes,
  UsersLiteralsTypes,
  SupportTicketsStatisticsUnit,
  Dashboard,
} from './models';
import { ActiveGatewaysMonthStatisticsModel } from 'core/domain/gateways/model/gateway/gatewayParsed';
import { UsersListUnstructuredStatisticsModel } from 'core/domain/users/models/usersModel';
import { SupportTicketsStatisticsModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import i18n from 'config/i18n';

export enum MonthName {
  JANUARY = 'january',
  FEBRUARY = 'february',
  MARCH = 'march',
  APRIL = 'april',
  MAY = 'may',
  JUNE = 'june',
  JULY = 'july',
  AUGUST = 'august',
  SEPTEMBER = 'september',
  OCTOBER = 'october',
  NOVEMBER = 'november',
  DECEMBER = 'december',
}

const monthTranslationManager = {
  [MonthName.JANUARY]: '_JANUARY_ABBREVIATION',
  [MonthName.FEBRUARY]: '_FEBRUARY_ABBREVIATION',
  [MonthName.MARCH]: '_MARCH_ABBREVIATION',
  [MonthName.APRIL]: '_APRIL_ABBREVIATION',
  [MonthName.MAY]: '_MAY_ABBREVIATION',
  [MonthName.JUNE]: '_JUNE_ABBREVIATION',
  [MonthName.JULY]: '_JULY_ABBREVIATION',
  [MonthName.AUGUST]: '_AUGUST_ABBREVIATION',
  [MonthName.SEPTEMBER]: '_SEPTEMBER_ABBREVIATION',
  [MonthName.OCTOBER]: '_OCTOBER_ABBREVIATION',
  [MonthName.NOVEMBER]: '_NOVEMBER_ABBREVIATION',
  [MonthName.DECEMBER]: '_DECEMBER_ABBREVIATION',
};

const getMonthAbbreviationTranslations = (month: MonthName): string => {
  return i18n.t(monthTranslationManager[month]);
};

export const getLabelsLiteralsUsersListStatistics = (labels: string[]): string[] => {
  return labels.map((label) => {
    const labelToLowerCase = label.toLowerCase() as MonthName;
    const labelTranslation = getMonthAbbreviationTranslations(labelToLowerCase);
    return labelTranslation;
  });
};

export const occupationLiterals: OccupationLiteralsTypes = {
  title: i18n.t('_OCCUPATION_TITLE'),
  titleTimePeriod: i18n.t('_OCCUPATION_TITLE_TIME_PERIOD'),
  occupied: i18n.t('_OCCUPATION_OCCUPIED'),
  unoccupied: i18n.t('_OCCUPATION_UNOCCUPIED'),
  errorTitle: i18n.t('_DASHBOARD_ERROR_TITLE'),
  errorDescription: i18n.t('_DASHBOARD_ERROR_DESCRIPTION'),
}

export const usersLiterals: UsersLiteralsTypes = {
  title: i18n.t('_USERS_ACTIVE'),
  titleTimePeriod: i18n.t('_USERS_ACTIVE_TIME_PERIOD'),
  usersAverage: i18n.t('_USERS_ACTIVE_AVERAGE'),
  errorTitle: i18n.t('_DASHBOARD_ERROR_TITLE'),
  errorDescription: i18n.t('_DASHBOARD_ERROR_DESCRIPTION'),
}

export const supportTicketsLiterals: SupportTicketsLiteralsTypes = {
  title: i18n.t('issues'),
  titleTimePeriod: i18n.t('_SUPPORT_TICKETS_MONTH_PERIOD_IN_DAYS'),
  openTickets: i18n.t('_SUPPORT_TICKETS_OPENED'),
  ticketsClosed: i18n.t('_SUPPORT_TICKETS_CLOSED'),
  ticketsClosedTimeAverage: i18n.t('_SUPPORT_TICKETS_CLOSED_TIME_AVERAGE'),
  firstResponseTimeAverage: i18n.t('_SUPPORT_TICKETS_FIRST_RESPONSE_TIME_AVERAGE'),
  ticketsReceived: i18n.t('_SUPPORT_TICKETS_RECEIVED'),
  ticketsRevoked: i18n.t('_SUPPORT_TICKETS_REVOKED'),
  ticketsInProgress: i18n.t('_SUPPORT_TICKETS_IN_PROGRESS'),
}

export const getLabelsLiteralsActiveGateways = (gateways: ActiveGatewaysMonthStatisticsModel[]): string[] => {
    return gateways.map(({ label }) => {
      const labelToLowerCase = label.toLowerCase() as MonthName;
      const labelTranslation = getMonthAbbreviationTranslations(labelToLowerCase);
      return labelTranslation;
    });
};

export const getInitialOccupationData = (): OccupationDashboardProps => ({
  assetsLinked: 0,
  assetsNotLinked: 0,
  literals: occupationLiterals,
  isError: false,
  isLoading: false,
});

const initialStatisticsActiveUsersData: UsersListUnstructuredStatisticsModel = {
  average: 0,
  labels: [],
  values: [],
};

export const getInitialActiveUsersData = () => ({
  isError: false,
  isLoading: false,
  isOpenedSupportTicketsLoading: false,
  statistics: initialStatisticsActiveUsersData,
  literals: usersLiterals,
});

const initialStatisticsSupportTicketsData: SupportTicketsStatisticsModel = {
  firstResponseTimeAverage: '00:00',
  firstResponseTimeAverageUnits: '',
  ticketsClosed: 0,
  ticketsRevoked: 0,
  ticketsInProgress: 0,
  ticketsClosedTimeAverage: '00:00',
  ticketsClosedTimeAverageUnits: '',
  ticketsReceived: 0,
};

export const getInitialSupportTicketsData = (): SupportTicketsDashboardProps => ({
  literals: supportTicketsLiterals,
  statistics: initialStatisticsSupportTicketsData,
  openTickets: 0,
  onClickOpened: () => null,
  isLoading: false,
  isError: false,
});

const initialStatisticsActiveGatewaysData: CustomActiveGatewaysStatisticsModel = {
  totalGatewaysOnline: 0,
  labels: [],
  values: [],
};

const initialActiveGatewaysData = {
  chartSubtitle: i18n.t('_ACTIVE_GATEWAYS_CHART_SUBTITLE'),
  errorDescription: i18n.t('_ACTIVE_GATEWAYS_CHART_ERROR_DESCRIPTION'),
  errorTitle: i18n.t('_ACTIVE_GATEWAYS_CHART_ERROR_TITLE'),
  subtitle: i18n.t('_ACTIVE_GATEWAYS_DATA_SUBTITLE'),
  text: i18n.t('_ACTIVE_GATEWAYS_TOTAL_GATEWAYS_TEXT'),
  title: i18n.t('_ACTIVE_GATEWAYS_TITLE'),
}

export const getInitialActiveGatewaysData = (): ActiveGatewaysDashboardProps => ({
  statistics: initialStatisticsActiveGatewaysData,
  literals: initialActiveGatewaysData,
  isError: false,
  isLoading: false,
})

const supportTicketsUnitsLiteralsManager = {
  [SupportTicketsStatisticsUnit.DAY]: () => ` ${i18n.t('_SUPPORT_TICKETS_UNITS_DAY')}`,
  [SupportTicketsStatisticsUnit.DAYS]: () => ` ${i18n.t('_SUPPORT_TICKETS_UNITS_DAYS')}`,
  [SupportTicketsStatisticsUnit.MINUTES]: () => i18n.t('_SUPPORT_TICKETS_UNITS_MINUTES'),
  [SupportTicketsStatisticsUnit.HOURS]: () => i18n.t('_SUPPORT_TICKETS_UNITS_HOURS'),
  [SupportTicketsStatisticsUnit.MINUTE]: () => i18n.t('_SUPPORT_TICKETS_UNITS_MINUTE'),
  [SupportTicketsStatisticsUnit.HOUR]: () => i18n.t('_SUPPORT_TICKETS_UNITS_HOUR'),
  [SupportTicketsStatisticsUnit.SECONDS]: () => i18n.t('_SUPPORT_TICKETS_UNITS_SECONDS'),
  [SupportTicketsStatisticsUnit.DEFAULT]: () => '',
};

export const formatUnits = (units?: string): string => {
  const value = !!units ? (units as SupportTicketsStatisticsUnit) : SupportTicketsStatisticsUnit.DEFAULT;
  return supportTicketsUnitsLiteralsManager[value]().toLowerCase();
};

export const dashboardInitialState = {
  [Dashboard.OCCUPATION]: getInitialOccupationData(),
  [Dashboard.ACTIVE_USERS]: getInitialActiveUsersData(),
  [Dashboard.SUPPORT_TICKETS]: getInitialSupportTicketsData(),
  [Dashboard.ACTIVE_GATEWAYS]: getInitialActiveGatewaysData(),
}
  