import { FC } from 'react';
import { Skeleton } from 'antd';
import './styles.scss';

const DashboardActiveGatewaysLoadingTemplate: FC = () => {
  return (
    <div className={'DashboardActiveGatewaysLoadingTemplate__wrapper'}>
      <div className='DashboardActiveGatewaysLoadingTemplate__data-content'>
        <Skeleton.Input className='DashboardActiveGatewaysLoadingTemplate__skeleton' active />
      </div>
      <div className='DashboardActiveGatewaysLoadingTemplate__data-content'>
        <Skeleton.Input className='DashboardActiveGatewaysLoadingTemplate__skeleton' active />
      </div>
    </div>
  );
}

export default DashboardActiveGatewaysLoadingTemplate;