import { FC } from 'react';
import Paper from 'components/atoms/Paper';
import PeripheralDevices from '../../../../organisms/PeripheralDevices';
import { useTabDevices } from './resources/useTabDevices';

const TabDevices: FC = () => {
  const {
    devices,
    gatewayId,
    peripheralsTitle,
    peripheralExpandedColumnsTranslations,
    searchInput,
    columnSearchTranslations,
    onSearch,
    onReset,
    loading,
  } = useTabDevices();

  return (
    <Paper>
      <PeripheralDevices
        loading={loading}
        gatewayId={gatewayId}
        devices={devices}
        title={peripheralsTitle}
        expandedColumnsTranslations={peripheralExpandedColumnsTranslations}
        searchInput={searchInput}
        columnSearchTranslations={columnSearchTranslations}
        onSearch={onSearch}
        onReset={onReset}
      />
    </Paper>
  );
};

export default TabDevices;
