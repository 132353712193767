import { FC } from 'react';
import DashboardActiveGatewaysLoadingTemplate from './loading';
import DashboardActiveGatewaysErrorTemplate from './error';
import DashboardActiveGatewaysDefaultTemplate from './default';
import { ActiveGatewaysDashboardProps } from 'components/pages/DashboardPage/resources/models';

const DashboardActiveGatewaysTemplate: FC<ActiveGatewaysDashboardProps> = (props) => {
  const { isError, isLoading } = props;

  if (isError) {
    return <DashboardActiveGatewaysErrorTemplate {...props} />
  }

  if (isLoading) {
    return <DashboardActiveGatewaysLoadingTemplate />
  }

  return (
    <DashboardActiveGatewaysDefaultTemplate {...props} />
  )
}

export default DashboardActiveGatewaysTemplate;