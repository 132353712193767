import { FC } from 'react';
import { Input } from 'antd';
import DrawerAtom from 'components/atoms/DrawerAtom';
import { SensorWithPasswordsModel } from 'core/domain/sensors/models/sensorEntityModels';
import AccessPasswordDrawerContent from 'components/molecules/AccessPasswordDrawerContent';
import { AccessPasswordDrawerTranslations, PasswordConfirmation, PasswordDeletingType, PasswordType, SensorPasswordModel, TogglePassword } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/utils';
import AccessPasswordDrawerInfoAlert from 'components/molecules/AccessPasswordDrawerInfoAlert';
import TextButton from 'components/atoms/Button/TextButton';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import './styles.scss';

interface Props {
  device: SensorWithPasswordsModel;
  adminPasswords: SensorPasswordModel[];
  adminAvailablePasswords: SensorPasswordModel[];
  nfcPasswords: SensorPasswordModel[];
  userPasswords: SensorPasswordModel[];
  userAvailablePasswords: SensorPasswordModel[];
  translations: AccessPasswordDrawerTranslations;
  onClose: () => void;
  onTogglePassword: ({ label, sensorId }: TogglePassword) => void;
  onChangePassword: ({ label, sensorId, value }: PasswordConfirmation) => void;
  onDeletePassword: ({ label, sensorId }: PasswordDeletingType) => void;
  onClickAddPassword: (label: PasswordType) => void;
  onSave: () => void;
  visible: boolean;
  adminPasswordsAddButtonVisible: boolean;
  userPasswordsAddButtonVisible: boolean;
  nfcPasswordsAddButtonVisible: boolean;
  adminSectionVisible: boolean;
  userSectionVisible: boolean;
  nfcSectionVisible: boolean;
  loading: boolean;
  disabled: boolean;
}

const AccessPasswordsDrawer: FC<Props> = ({
  device,
  adminPasswords,
  nfcPasswords,
  userPasswords,
  translations,
  onClose,
  onTogglePassword,
  onChangePassword,
  onDeletePassword,
  onClickAddPassword,
  onSave,
  visible,
  adminPasswordsAddButtonVisible,
  userPasswordsAddButtonVisible,
  nfcPasswordsAddButtonVisible,
  adminSectionVisible,
  userSectionVisible,
  nfcSectionVisible,
  loading,
  disabled,
}) => {
  const {
    adminLabel,
    userLabel,
    nfcLabel,
    typeLabel,
    passwordLabel,
    addPasswordText,
    showPasswordText,
    hidePasswordText,
    deletePasswordText,
    errorFormatMessage,
    infoTitle,
    infoMinimumDigitsText,
    infoMaximumDigitsText,
    infoValidDigitsText,
    infoNfcTitle,
    infoNfcMinimumDigitsText,
    infoNfcMaximumDigitsText,
    infoNfcValidDigitsText,
    infoNfcExtraText,
    saveText,
    closeText,
  } = translations;

  return (
    <DrawerAtom
      className={'AccessPasswordDrawer__wrapper'}
      title={<span className={'AccessPasswordDrawer__title'}>{device.deviceName}</span>}
      destroyOnClose={true}
      closable={!loading}
      maskClosable={!loading}
      onClose={onClose}
      placement={'right'}
      size={'large'}
      open={visible}
      style={{ zIndex: 2 }}
    >
      <main className={'AccessPasswordDrawer__main'}>
        <div className={'AccessPasswordDrawer__main-wrapper'}>
          <p className={'AccessPasswordDrawer__main-label'}>{typeLabel}</p>
          <Input disabled={true} value={device.deviceType} />
        </div>
        <div className={'AccessPasswordDrawer__info'}>
          <AccessPasswordDrawerInfoAlert
            infoTitle={infoTitle}
            infoMinimumDigitsText={infoMinimumDigitsText}
            infoMaximumDigitsText={infoMaximumDigitsText}
            infoValidDigitsText={infoValidDigitsText}
            infoNfcTitle={infoNfcTitle}
            infoNfcMinimumDigitsText={infoNfcMinimumDigitsText}
            infoNfcMaximumDigitsText={infoNfcMaximumDigitsText}
            infoNfcValidDigitsText={infoNfcValidDigitsText}
            infoNfcExtraText={infoNfcExtraText}
            isNfcInfoVisible={nfcSectionVisible}
          />
        </div>
        <div>
          {adminSectionVisible && (
            <div className={'AccessPasswordDrawer__content-wrapper'}>
              <div className={'AccessPasswordDrawer__content-wrapper-header'}>
                <p className={'AccessPasswordDrawer__content-title'}>{adminLabel}</p>
                {adminPasswordsAddButtonVisible && (
                  <TextButton
                    title={addPasswordText}
                    className={'AccessPasswordDrawer__content-wrapper-header-button'}
                    onClick={() => onClickAddPassword(PasswordType.ADMIN_PASSWORD)}
                  />
                )}
              </div>
              {adminPasswords.map((sensor, index) => (
                <AccessPasswordDrawerContent
                  key={index}
                  sensor={sensor}
                  label={PasswordType.ADMIN_PASSWORD}
                  passwordText={passwordLabel}
                  onTogglePassword={onTogglePassword}
                  onChangePassword={onChangePassword}
                  onDeletePassword={onDeletePassword}
                  buttonShowText={showPasswordText}
                  buttonHideText={hidePasswordText}
                  buttonDeleteText={deletePasswordText}
                  errorFormatMessage={errorFormatMessage}
                  disabled={false}
                />
              ))}
            </div>
          )}
          {userSectionVisible && (
            <div className={'AccessPasswordDrawer__content-wrapper'}>
              <div className={'AccessPasswordDrawer__content-wrapper-header'}>
                <p className={'AccessPasswordDrawer__content-title'}>{userLabel}</p>
                {userPasswordsAddButtonVisible && (
                  <TextButton
                    title={addPasswordText}
                    className={'AccessPasswordDrawer__content-wrapper-header-button'}
                    onClick={() => onClickAddPassword(PasswordType.USER_PASSWORD)}
                  />
                )}
              </div>
              {userPasswords.map((sensor, index) => (
                <AccessPasswordDrawerContent
                  key={index}
                  sensor={sensor}
                  label={PasswordType.USER_PASSWORD}
                  passwordText={passwordLabel}
                  onTogglePassword={onTogglePassword}
                  onChangePassword={onChangePassword}
                  onDeletePassword={onDeletePassword}
                  buttonShowText={showPasswordText}
                  buttonHideText={hidePasswordText}
                  buttonDeleteText={deletePasswordText}
                  errorFormatMessage={errorFormatMessage}
                  disabled={false}
                />
              ))}
            </div>
          )}
          {nfcSectionVisible && (
            <div className={'AccessPasswordDrawer__content-wrapper'}>
              <div className={'AccessPasswordDrawer__content-wrapper-header'}>
                <p className={'AccessPasswordDrawer__content-title'}>{nfcLabel}</p>
                {nfcPasswordsAddButtonVisible && (
                  <TextButton
                    title={addPasswordText}
                    className={'AccessPasswordDrawer__content-wrapper-header-button'}
                    onClick={() => onClickAddPassword(PasswordType.NFC_PASSWORD)}
                  />
                )}
              </div>
              {nfcPasswords.map((sensor, index) => (
                <AccessPasswordDrawerContent
                  key={index}
                  sensor={sensor}
                  label={PasswordType.NFC_PASSWORD}
                  passwordText={passwordLabel}
                  onTogglePassword={onTogglePassword}
                  onChangePassword={onChangePassword}
                  onDeletePassword={onDeletePassword}
                  buttonShowText={showPasswordText}
                  buttonHideText={hidePasswordText}
                  buttonDeleteText={deletePasswordText}
                  errorFormatMessage={errorFormatMessage}
                  disabled={!sensor.isEditable}
                />
              ))}
            </div>
          )}
        </div>
      </main>
      <footer>
        <DefaultButton onClick={onClose} title={closeText} />
        <PrimaryButton loading={loading} title={saveText} onClick={onSave} disabled={disabled} />
      </footer>
    </DrawerAtom>
  )
}

export default AccessPasswordsDrawer;