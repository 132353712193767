import { FC } from 'react';
import DashboardTemplate from 'components/template/DashboardTemplate';
import { useDashboard } from './resources/useDashboard';
import './styles.scss';

const DashboardPage: FC = () => {
  const {
    occupation,
    activeUsers,
    supportTickets,
    activeGateways,
  } = useDashboard();

  return (
    <DashboardTemplate
      occupation={occupation}
      activeUsers={activeUsers}
      supportTickets={supportTickets}
      activeGateways={activeGateways}
    />
  );
};

export default DashboardPage;
