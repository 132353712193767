import { FC } from 'react';
import AsChart from 'components/atoms/AsChart';
import { configuration } from './resources/config';
import { ActiveUsersDashboardProps } from 'components/pages/DashboardPage/resources/models';
import './styles.scss';

const DashboardUsersDefaultTemplate: FC<ActiveUsersDashboardProps> = ({ statistics, literals }) => {
  const { average, labels, values } = statistics;
  const { data: { datasets } } = configuration;
  const data = { datasets, labels };
  data.datasets[0].data = values;

  return (
    <div className='UsersTemplate__container'>
      <div className='UsersTemplate__header'>
        <div>
          <p className='UsersTemplate__title'>{literals.title}</p>
          <p className='UsersTemplate__subtitle'>{literals.titleTimePeriod}</p>
        </div>
        <p className='UsersTemplate__average'>{`${average} ${literals.usersAverage.toLowerCase()}`}</p>
      </div>
      <div className='UsersTemplate__chart-container'>
        <div className='UsersTemplate__chart-content'>
          <AsChart
            configuration={{ ...configuration, data }}
            canvasWidth={'700'}
            canvasHeight={'260'}
          />
        </div>
      </div>
    </div>
  );
}

export default DashboardUsersDefaultTemplate;