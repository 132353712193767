import { FC } from 'react';
import RegisterUserForm from 'components/organisms/RegisterUserForm';
import RegisterConfirmation from 'components/organisms/RegisterUserForm/RegisterUserConfirmation';
import { IMAGES } from 'constants/images';
import { useRegisterUser } from './resources/useRegisterUser';
import './styles.scss';

const RegisterUserPage: FC = () => {
  const {
    alignment,
    handleOnForm,
    isLoading,
    isRegistering,
  } = useRegisterUser();

  return (
    <div className={`RegisterUserPage ${alignment}`}>
      <img
        src={IMAGES.logoAlfred}
        title={'Alfred Smart Systems'}
        alt={'Alfred Smart Systems'}
        className={'RegisterUserPage__logo'}
      />
      {isRegistering ? (
        <RegisterUserForm
          onSubmit={handleOnForm}
          loading={isLoading}
          linkLopd={'https://auth.alfredsmartdata.com/terms-of-service/es'}
        />
      ) : (
        <RegisterConfirmation />
      )}
    </div>
  );
};

export default RegisterUserPage;
