import { FC } from 'react';
import { Input, Pagination, TableProps } from 'antd';
import TableAtom from 'components/atoms/TableAtom';
import { AccessDeviceModel } from 'core/domain/access/models';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import Paper from 'components/atoms/Paper';
import { TABLE_PAGE_SIZE_DEFAULT_OPTIONS, TABLE_PAGINATION_SIZE_DEFAULT } from 'constants/table';
import './styles.scss';

interface Props {
  accessDevicesList: AccessDeviceModel[];
  columns: AlfredTableColumn<AccessDeviceModel>[];
  loading: boolean;
  page: number;
  size: number;
  totalPages: number;
  searchButtonText: string;
  inputRef: any;
  searchValue: string;
  onChangePage: (page: number, pageSize: number) => void;
  onUpdateSearchValue: (value: string) => void;
  onSearch: (value: string) => void;
  onChangeAccessDevicesTable: TableProps<AccessDeviceModel>['onChange'],
}

const MAX_SIZE_RESPONSIVE_SCREEN = 600;

const AccessDevicesListTemplate: FC<Props> = ({
  accessDevicesList,
  columns,
  page,
  size,
  totalPages,
  inputRef,
  searchValue,
  searchButtonText,
  onChangePage,
  onSearch,
  onUpdateSearchValue,
  onChangeAccessDevicesTable,
  loading,
}) => {
  return (
    <Paper className={'AccessDevicesListTemplate__wrapper'}>
      <div className={'AccessDevicesListTemplate__extra-button'}>
        <Input.Search
          ref={inputRef}
          value={searchValue}
          autoFocus
          allowClear
          placeholder={searchButtonText}
          onSearch={onSearch}
          onChange={(event) => onUpdateSearchValue(event.target.value)}
        />
      </div>
      <TableAtom<AccessDeviceModel>
        loading={loading}
        columns={columns}
        dataSource={accessDevicesList}
        rowKey='id'
        pagination={false}
        scroll={{ x: MAX_SIZE_RESPONSIVE_SCREEN }}
        onChange={onChangeAccessDevicesTable}
        onRow={(item) => {
          return {
            onClick: () => { },
          };
        }}
      />
      <Pagination
        className={'AccessDevicesListTemplate__pagination'}
        size={'small'}
        showSizeChanger={true}
        defaultPageSize={Number(TABLE_PAGINATION_SIZE_DEFAULT)}
        pageSizeOptions={TABLE_PAGE_SIZE_DEFAULT_OPTIONS}
        current={page}
        total={size * totalPages}
        pageSize={size}
        onChange={onChangePage}
      />
    </Paper>
  );
};

export default AccessDevicesListTemplate;
