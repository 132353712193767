import { FC } from 'react';
import AsChart from 'components/atoms/AsChart';
import { configuration } from './resources/config';
import './styles.scss';
import { ActiveGatewaysDashboardProps } from 'components/pages/DashboardPage/resources/models';

const DashboardActiveGatewaysDefaultTemplate: FC<ActiveGatewaysDashboardProps> = ({
  literals,
  statistics,
}) => {
  const { title, subtitle, text, chartSubtitle } = literals;
  const { data: { datasets } } = configuration;
  const { labels, values, totalGatewaysOnline } = statistics;
  const data = { datasets, labels };
  data.datasets[0].data = values;

  return (
    <div className={'DashboardActiveGatewaysTemplate__wrapper'}>
      <div className={'DashboardActiveGatewaysTemplate__data'}>
        <div className='DashboardActiveGatewaysTemplate__data-header'>
          <p className='DashboardActiveGatewaysTemplate__title'>{title}</p>
          <p className='DashboardActiveGatewaysTemplate__subtitle'>{subtitle}</p>
        </div>
        <div className='DashboardActiveGatewaysTemplate__data-content'>
          <p className='DashboardActiveGatewaysTemplate__data-content-info'>{text}</p>
          <p className='DashboardActiveGatewaysTemplate__data-content-info-value'>{totalGatewaysOnline}</p>
        </div>
      </div>
      <div className='DashboardActiveGatewaysTemplate__chart'>
        <div className='DashboardActiveGatewaysTemplate__chart-header'>
          <p className='UsersTemplate__subtitle'>{chartSubtitle}</p>
        </div>
        <div className='DashboardActiveGatewaysTemplate__chart-content'>
          <div className={'DashboardActiveGatewaysTemplate__chart-content-limit'}>
            <AsChart
              configuration={{ ...configuration, data }}
              canvasWidth={'700'}
              canvasHeight={'260'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardActiveGatewaysDefaultTemplate;