import { FC } from 'react';
import { SupportTicketsDashboardProps } from 'components/pages/DashboardPage/resources/models';
import './styles.scss';

const DashboardSupportTicketsErrorTemplate: FC<SupportTicketsDashboardProps> = ({ literals }) => {
  return (
    <div className='SupportTicketsErrorTemplate__container'>
      <div className='SupportTicketsErrorTemplate__header'>
        <div>
          <p className='SupportTicketsErrorTemplate__title'>
            {literals.title}
          </p>
          <p className='SupportTicketsErrorTemplate__subtitle'>
            {literals.titleTimePeriod}
          </p>
        </div>
        <div>
          <p className='SupportTicketsErrorTemplate__average'>
            {`${literals.openTickets}: --`}
          </p>
        </div>
      </div>
      <div className='SupportTicketsErrorTemplate__chart-container'>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {literals.ticketsClosedTimeAverage}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {literals.firstResponseTimeAverage}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {literals.ticketsReceived}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {literals.ticketsClosed}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {literals.ticketsInProgress}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
        <div className='SupportTicketsErrorTemplate__chart-element'>
          <p className='SupportTicketsErrorTemplate__chart-element-title'>
            {literals.ticketsRevoked}
          </p>
          <span className='SupportTicketsErrorTemplate__chart-element-data'>-</span>
        </div>
      </div>
    </div>
  );
}

export default DashboardSupportTicketsErrorTemplate;