import { FC } from 'react';
import { Skeleton } from 'antd';
import Paper from 'components/atoms/Paper';
import { useAssetGateway } from './resources/useAssetGateways';
import { NoGatewayAssigned } from './NoGatewayAssigned';
import GatewayStatus from './GatewayStatus';
import ConfirmUnAssignGatewayModal from 'components/molecules/ConfirmUnAssignGatewayModal';

const TabGateway: FC = () => {
  const {
    assetId,
    gatewaysOptions,
    gatewayActionsOptions,
    loading
  } = useAssetGateway();

  const {
    gateways,
    gatewayStatusInformationTranslations,
    onUpdateGateway,
    hasPermissionToEditGatewayLink,
  } = gatewaysOptions;

  const {
    hasLinkedGateway,
    onAssignGateway,
    unassignTranslations,
    onUnassignGateway,
    onConfirmUnassignGateway,
    loadingGatewayAssignation,
    onCancelConfirmationOnUnAssignGatewayModal,
    showConfirmationOnUnassignGatewayModal,
  } = gatewayActionsOptions;

  const {
    confirmationOnUnassignGatewayModalTitle,
    confirmationOnUnassignGatewayModalText,
    confirmationOnUnassignGatewayModalMainButtonText,
    confirmationOnUnassignGatewayModalSecondaryButtonText,
  } = unassignTranslations;

  return (
    <>
      {loading && (
        <Paper>
          <Skeleton active />
        </Paper>
      )}
      {!loading && !hasLinkedGateway && (
        <NoGatewayAssigned
          assetId={assetId}
          onAssignGateway={onAssignGateway}
          hasPermissionToEditGatewayLink={hasPermissionToEditGatewayLink}
        />
      )}
      {!loading && hasLinkedGateway && (
        <>
          {gateways.map((gateway) => {
            return (
              <div key={gateway.id}>
                <Paper>
                  <GatewayStatus
                    gateway={gateway}
                    informationTranslations={gatewayStatusInformationTranslations}
                    onUnassignGateway={() => onUnassignGateway(gateway.id)}
                    onUpdateGateway={onUpdateGateway}
                    hasPermissionToEditGatewayLink={hasPermissionToEditGatewayLink}
                    isGatewayLinked={!!gateway.assetId}
                  />
                </Paper>
              </div>
            )
          })}
        </>
      )}
      <ConfirmUnAssignGatewayModal
        title={confirmationOnUnassignGatewayModalTitle}
        text={confirmationOnUnassignGatewayModalText}
        mainButtonText={confirmationOnUnassignGatewayModalMainButtonText}
        secondaryButton={confirmationOnUnassignGatewayModalSecondaryButtonText}
        onClick={onConfirmUnassignGateway}
        open={showConfirmationOnUnassignGatewayModal}
        confirmLoading={loadingGatewayAssignation}
        onCancel={onCancelConfirmationOnUnAssignGatewayModal}
      />
    </>
  );
};

export default TabGateway;
