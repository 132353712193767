import { GatewayModuleType, GatewayUpdateProcessType } from 'core/domain/gateways/model';
import { ActiveIssue } from 'core/domain/gateways/model/activeIssue';
import { GatewayDetailStatusModel, GatewayParsed, LinksModelType } from 'core/domain/gateways/model/gateway/gatewayParsed';
import { GatewayType } from 'core/domain/gateways/model/gateway/gatewayUnparsed';
import { GatewayStatusModel, GatewayStatusUnitsModel } from 'core/domain/gateways/model/gatewayStatus';
import {
  GatewayNetworkInterfaceModel,
  GatewayNetworkStateStatusModel,
} from 'core/domain/gateways/model/gatewayStatus/gatewayStatusPayload';

export enum CheckValuesType {
  ZWAVE_CONTROLLER = 'zwaveController',
  HELPER_API = 'helperApi',
  VPN_CONNECTION = 'vpnConnection',
  ASSISTANT_CLOUD_SERVICE = 'assistantCloudService',
}

export enum PercentValuesType {
  CPU_TEMPERATURE = 'cpuTemperature',
  CPU_RAM_USED = 'cpuRamUsed',
  CPU_HDD_USED = 'cpuHddUsed',
  CPU_LOAD = 'cpuLoad',
}

export interface GatewayStatusCheckValueModel {
  check: boolean;
  text: string;
}

export interface GatewayStatusPercentValueModel {
  value: number;
  threshold: number;
  unit: string;
  text: string;
}

export interface GatewayStatusPercentValueInfoModel {
  value: number;
  threshold: number;
  units: GatewayStatusUnitsModel | null;
}

export interface GatewayStatusModuleConfig {
  isVersionUpdateError: boolean;
  isVersionUpdating: boolean;
  isUpdateButtonDisabled: boolean;
  isUpdateButtonVisible: boolean;
  lastVersion: string;
}

export interface CustomGatewayDataModel extends GatewayParsed {
  assetId?: string;
  checkedValues: GatewayStatusCheckValueModel[];
  percentValues: GatewayStatusPercentValueModel[];
  aassLastVersion: string;
  systemConfig: GatewayStatusModuleConfig;
}

export type GatewayStatusKeys = keyof GatewayStatusModel;

export interface CheckValuesManager {
  [key: string]: (element: boolean) => GatewayStatusCheckValueModel;
}

export interface PercentValuesManager {
  [key: string]: (element: string) => GatewayStatusPercentValueModel;
}

export interface CustomManagementLinksModel {
  [LinksModelType.OPENHAB]: {
    url: string | null;
    disabled: boolean;
    title: string;
  };
  [LinksModelType.SSH_TELEPORT]: {
    url: string | null;
    disabled: boolean;
    title: string;
  };
}

export interface ActiveIssuesDetailTranslationsModel {
  [ActiveIssue.ASSISTANT_CLOUD_SERVICE]: string;
  [ActiveIssue.CPU_HDD_USED]: string;
  [ActiveIssue.CPU_HD_AVAILABLE]: string;
  [ActiveIssue.CPU_HD_USED]: string;
  [ActiveIssue.CPU_LOAD]: string;
  [ActiveIssue.CPU_RAM]: string;
  [ActiveIssue.CPU_RAM_AVAILABLE]: string;
  [ActiveIssue.CPU_RAM_USED]: string;
  [ActiveIssue.CPU_TEMPERATURE]: string;
  [ActiveIssue.OHS_API]: string;
  [ActiveIssue.OHS_SERVICE]: string;
  [ActiveIssue.ONLINE]: string;
  [ActiveIssue.OPENHAB_API]: string;
  [ActiveIssue.OPENHAB_SERVICED]: string;
  [ActiveIssue.SYSTEM_TIME]: string;
  [ActiveIssue.TOTAL_INODES]: string;
  [ActiveIssue.TOTAL_SWAP]: string;
  [ActiveIssue.UPTIME]: string;
  [ActiveIssue.USED_INODES]: string;
  [ActiveIssue.USED_SWAP]: string;
  [ActiveIssue.VPN_CONNECTION]: string;
  [ActiveIssue.VPN_IP]: string;
  [ActiveIssue.ZWAVE_CONTROLLER]: string;
}

export interface GatewayNetworkInterfaceTranslationsModel {
  [GatewayNetworkInterfaceModel.ETHERNET]: string;
  [GatewayNetworkInterfaceModel.WIFI]: string;
  [GatewayNetworkInterfaceModel.LTE]: string;
}

export interface GatewayStatusNetworkStateStatusTranslationsModel {
  [GatewayNetworkStateStatusModel.GOOD]: string;
  [GatewayNetworkStateStatusModel.MEDIUM]: string;
  [GatewayNetworkStateStatusModel.BAD]: string;
  [GatewayNetworkStateStatusModel.UNKNOWN]: string;
}

export interface GatewayModuleCheckerModel {
  module: GatewayModuleType;
  state: GatewayUpdateProcessType;
}

export interface GatewayStatusInformationTranslations {
  title: string;
  notReportedText: string;
  productionDateText: string;
  networkStateText: string;
  networkStateStatusTranslations: GatewayStatusNetworkStateStatusTranslationsModel;
  networkStateLatencyText: string;
  networkStateLostPackagesText: string;
  networkStateBandWidthText: string;
  networkInterfaceText: string;
  networkInterfaceDetailTranslations: GatewayNetworkInterfaceTranslationsModel;
  lastConnectionText: string;
  statusText: string;
  statusOfflineText: string;
  statusOnlineText: string;
  healthText: string;
  activeIssuesText: string;
  activeIssuesDetailTranslations: ActiveIssuesDetailTranslationsModel;
  gatewayVersionText: string;
  gatewayVersionUpdateButtonText: string;
  updateModuleErrorMessage: string;
  updateButtonInProgressText: string;
}

export const INITIAL_GATEWAY_STATUS: GatewayDetailStatusModel = {
  activeIssues: null,
  projectId: '',
  assetId: '',
  assistantCloudService: false,
  cpuHddUsed: null,
  cpuLoad: null,
  cpuRamUsed: null,
  cpuTemperature: null,
  deploymentId: '',
  firmwareVersion: '',
  gatewayId: '',
  gatewayVersion: '',
  health: null,
  helperApi: null,
  helperService: null,
  productionDate: 0,
  helperVersion: '',
  hwVersion: '',
  knxVersion: '',
  lastConnection: null,
  location: '',
  networkInterface: null,
  networkState: {
    bandWidth: 0,
    latency: 0,
    percentagePackageLost: 0,
    status: GatewayNetworkStateStatusModel.UNKNOWN,
  },
  timeOffline: null,
  totalInodes: null,
  totalSwap: null,
  uptime: null,
  usedInodes: null,
  usedSwap: null,
  vpnConnection: null,
  vpnIp: '',
  zwaveVersion: '',
  zwaveController: null,
  thresholds: {
    timeOffline: 0,
    cpuTemperature: 0,
    cpuRamUsed: 0,
    cpuHddUsed: 0,
    cpuLoad: 0,
    usedInodes: 0,
    usedSwap: 0,
  },
  units: {
    cpuTemperature: '',
    cpuLoad: '',
    cpuRam: '',
    systemTime: '',
    uptime: '',
    openhabApi: '',
    openhabServiced: '',
    cpuHddUsed: '',
    zwaveController: '',
    assistantCloudService: '',
    vpnConnection: '',
    cpuRamUsed: '',
    cpuHdUsed: '',
    ohsApi: '',
    ohsService: '',
    cpuHdAvailable: '',
    cpuRamAvailable: '',
    totalSwap: '',
    usedSwap: '',
    totalInodes: '',
    usedInodes: '',
    vpnIp: '',
    batteryLevel: '',
    connectivity: '',
    internalTemperature: '',
    latency: '',
  },
};

export const INITIAL_CUSTOM_GATEWAY: CustomGatewayDataModel = {
  assetId: '',
  aassLastVersion: '',
  assistantCloud: '',
  assistantCloudUrl: '',
  batchId: '',
  batchName: '',
  daemonVersion: '',
  deployedAt: null,
  deploymentDate: null,
  devices: [],
  firmwareVersion: '',
  gatewayVersion: '',
  helperVersion: '',
  hwVersion: '',
  id: '',
  knxVersion: '',
  managementLinks: {
    [LinksModelType.CONTROL_PANEL]: null,
    [LinksModelType.OPENHAB]: null,
    [LinksModelType.SSH_TELEPORT]: null,
  },
  masterUser: '',
  osVersion: '',
  productionDate: 0,
  projectId: '',
  qrCode: '',
  sku: null,
  sensors: null,
  checkedValues: [],
  status: INITIAL_GATEWAY_STATUS,
  type: GatewayType.PRIVATE,
  voiceAssistantUser: '',
  percentValues: [],
  systemConfig: {
    isUpdateButtonDisabled: false,
    isUpdateButtonVisible: true,
    isVersionUpdateError: false,
    isVersionUpdating: false,
    lastVersion: '',
  },
  zwaveVersion: '',
  sharedSensors: null,
};
