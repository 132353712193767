import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { GetClientsList } from 'core/domain/client/repositories/getClientsList';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { GetProjectsListByClientId } from 'core/domain/project/repositories/projectsCRUD';
import { ProjectListResponseDataModel } from 'core/domain/project/model/projectModel';
import {
  getBuildingAccessesRoomListPath,
  getHomeAccessesRoomListPath,
  getOfficeAccessDevicesListWithPaginationPath,
  getHotelAccessesRoomListPath,
  getHotelCheckInListWithPaginationPath,
  getHotelRoomAccessesRoomListPath,
  getOfficeLogListWithPaginationPath,
  getParkingSlotAccessesRoomListPath,
  getRentAccessesRoomListPath,
  getVacationalRentalAccessesRoomListPath,
  getHotelAccessDevicesListWithPaginationPath,
} from 'components/pages/App/routes/access/config';
import { ProjectVerticalsEnum } from 'constants/verticals';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { HotelAccessesTabType, OfficeAccessesTabType, RouteSegmentType, TabTranslations } from './utils';
import { getLastClientIdAndProjectId } from 'core/domain/access/repository/getLastClientIdAndProjectId';
import { setCurrentClientIdAndProjectId } from 'core/domain/access/repository/setCurrentClientIdAndProjectId';
import { messageAtom } from 'components/atoms/MessageAtom';
import UserProfileContext, { UserProfileContextState } from 'context/UserProfileContext';

export interface AccessClientAndProjectCustomData {
  value: string;
  label: string;
}

interface RouteManagerModel {
  client?: string;
  project?: string;
  projectType?: string;
  tab?: string;
}

interface NavigationDataModel {
  clientId: string;
  projectId: string;
  page: string;
  size: string;
}

interface NavigationModel {
  tab?: string;
  data: NavigationDataModel;
}

export const useAccessesClientAndProjectSelector = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userProfile } = useContext<UserProfileContextState>(UserProfileContext);
  const [isLoadingClientsList, setIsLoadingClientsList] = useState<boolean>(false);
  const [isLoadingProjectsList, setIsLoadingProjectsList] = useState<boolean>(false);
  const [clientsList, setClientsList] = useState<AccessClientAndProjectCustomData[]>([]);
  const [currentClient, setCurrentClient] = useState<string | null>(null);
  const [currentProject, setCurrentProject] = useState<string | null>(null);
  const [projectsList, setProjectsList] = useState<AccessClientAndProjectCustomData[]>([]);
  const [projectsDataList, setProjectsDataList] = useState<ProjectListResponseDataModel[]>([]);
  const [showEmptyMessage, setShowEmptyMessage] = useState<boolean>(true);
  const [areTabsVisible, setAreTabsVisible] = useState<boolean>(false);
  const [selectedHotelTab, setSelectedHotelTab] = useState<HotelAccessesTabType>(HotelAccessesTabType.ROOM_LIST);
  const [selectedOfficeTab, setSelectedOfficeTab] = useState<OfficeAccessesTabType>(OfficeAccessesTabType.LOG_LIST);
  const [projectType, setProjectType] = useState<ProjectVerticalsEnum | null>(null);
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE);
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE);

  const title: string = t('_ACCESSES_TITLE');
  const clientPlaceholderText: string = t('_ACCESSES_CLIENT_SELECTOR_PLACEHOLDER');
  const projectPlaceholderText: string = t('_ACCESSES_PROJECT_SELECTOR_PLACEHOLDER');
  const emptyText: string = t('_ACCESSES_WITH_NO_CLIENT_AND_PROJECT_EMPTY_TEXT');

  const tabsTranslations: TabTranslations = {
    roomsListText: t('_ROOMS_LIST_TAB_TITLE'),
    checkInListText: t('_CHECK_IN_LIST_TAB_TITLE'),
    logListText: t('_ACCESSES_LOG_LIST_TAB_TITLE'),
    accessDevicesListText: t('_ACCESS_DEVICES_LIST_TAB_TITLE'),
  };

  const onSelectClient = (value: string) => {
    setCurrentClientIdAndProjectId({ clientId: value });
    setCurrentClient(value);
    setShowEmptyMessage(true);
    setCurrentProject(null);
    setAreTabsVisible(false);
    setProjectsList([]);
    setProjectType(null);
  };

  const hotelAccessesTabNavigationManager = {
    [HotelAccessesTabType.ROOM_LIST]: getHotelAccessesRoomListPath,
    [HotelAccessesTabType.CHECK_IN_LIST]: getHotelCheckInListWithPaginationPath,
    [HotelAccessesTabType.ACCESS_DEVICES_LIST]: getHotelAccessDevicesListWithPaginationPath,
  };

  const getHotelAccessesListPath = ({ data, tab }: NavigationModel) =>
    hotelAccessesTabNavigationManager[(!!tab ? tab : selectedHotelTab) as HotelAccessesTabType](data)

  const officeAccessesTabNavigationManager = {
    [OfficeAccessesTabType.LOG_LIST]: getOfficeLogListWithPaginationPath,
    [OfficeAccessesTabType.ACCESS_DEVICES_LIST]: getOfficeAccessDevicesListWithPaginationPath,
  };

  const getOfficeAccessesListPath = ({ data, tab }: NavigationModel) =>
    officeAccessesTabNavigationManager[(!!tab ? tab : selectedOfficeTab) as OfficeAccessesTabType](data)

  const accessesRoomsListNavigationManager: { [name: string]: (arg: NavigationModel) => string } = {
    [ProjectVerticalsEnum.BUILDING]: ({ data }: NavigationModel) => getBuildingAccessesRoomListPath(data),
    [ProjectVerticalsEnum.HOME]: ({ data }: NavigationModel) => getHomeAccessesRoomListPath(data),
    [ProjectVerticalsEnum.HOTEL_ROOM]: ({ data }: NavigationModel) => getHotelRoomAccessesRoomListPath(data),
    [ProjectVerticalsEnum.HOTEL]: ({ data, tab }: NavigationModel) => getHotelAccessesListPath({ data, tab }),
    [ProjectVerticalsEnum.OFFICE]: ({ data, tab }: NavigationModel) => getOfficeAccessesListPath({ data, tab }),
    [ProjectVerticalsEnum.PARKING_SLOT]: ({ data }: NavigationModel) => getParkingSlotAccessesRoomListPath(data),
    [ProjectVerticalsEnum.RENT]: ({ data }: NavigationModel) => getRentAccessesRoomListPath(data),
    [ProjectVerticalsEnum.VACATIONAL_RENTAL]: ({ data }: NavigationModel) => getVacationalRentalAccessesRoomListPath(data),
  }

  const navigateToAccessesListByType = ({
    clientId,
    projectId,
    type,
    tab,
  }: {
    clientId: string;
    projectId: string;
    type: string;
    tab?: string;
  }) => {
    const parsedPage = page || TABLE_PAGINATION_PAGE_DEFAULT;
    const parsedSize = size || TABLE_PAGINATION_SIZE_DEFAULT;
    const navigationData: NavigationDataModel = {
      clientId,
      projectId,
      page: !!currentClient && !currentProject ? TABLE_PAGINATION_PAGE_DEFAULT : parsedPage,
      size: !!currentClient && !currentProject ? TABLE_PAGINATION_SIZE_DEFAULT : parsedSize,
    };

    const accessesRoomListRoute = accessesRoomsListNavigationManager[type as ProjectVerticalsEnum]({ tab, data: navigationData });
    history.push(accessesRoomListRoute);
  };

  const checkIfTabsAreVisible = (type: ProjectVerticalsEnum) => {
    const isHotelType = type === ProjectVerticalsEnum.HOTEL;
    const isOfficeType = type === ProjectVerticalsEnum.OFFICE;
    setAreTabsVisible(isHotelType || isOfficeType);
  };

  const updateSelectedProject = ({ projectId, type }: { projectId: string; type: string }) => {
    !!currentClient && navigateToAccessesListByType({ clientId: currentClient, projectId, type });
    setProjectType(type as ProjectVerticalsEnum);
    setCurrentProject(projectId);
    setShowEmptyMessage(false);
    checkIfTabsAreVisible(type as ProjectVerticalsEnum);
  };

  const onSelectProject = (value: string) => {
    const selectedProject = projectsDataList.find((project) => project.id === value);
    selectedProject && setCurrentClientIdAndProjectId({ projectId: value, projectType: selectedProject.type });
    selectedProject && updateSelectedProject({ projectId: value, type: selectedProject.type });
  };

  const filterProjectAndValidateTabsVisibility = (projects: ProjectListResponseDataModel[]) => {
    const selectedProject = projects.find((project) => project.id === currentProject);
    selectedProject && checkIfTabsAreVisible(selectedProject.type as ProjectVerticalsEnum);
    selectedProject && setProjectType(selectedProject.type as ProjectVerticalsEnum);
  };

  const transformClientsListData = (clients: ClientModel[]): AccessClientAndProjectCustomData[] => {
    return clients.map(({ id, name }) => ({ value: id, label: name }));
  };

  const getCurrentClient = async (clients: AccessClientAndProjectCustomData[]) => {
    const splitRoute = window.location.pathname.split('/');
    const index = splitRoute.length;
    const { clientId, projectId } = getLastClientIdAndProjectId();
    const isDefaultAccessUrlRoute = index < 4;
    if (!!userProfile?.client?.id && isDefaultAccessUrlRoute && !clientId && !projectId) {
      const selectedClient = clients.find(({ value }) => userProfile.client?.id === value);
      selectedClient && setCurrentClient(selectedClient.value);
    }
  };

  const getClientsList = async () => {
    setIsLoadingClientsList(true);
    try {
      const clients = await GetClientsList();
      if (!!clients?.length) {
        const transformedClients = transformClientsListData(clients);
        await getCurrentClient(transformedClients);
        setClientsList(transformedClients);
      }
    } catch (error) {
      messageAtom.error(t('_ACCESSES_CLIENT_AND_PROJECT_CLIENT_ERROR'), 5);
    } finally {
      setIsLoadingClientsList(false);
    }
  };

  const transformProjectsListData = (clients: ProjectListResponseDataModel[]): AccessClientAndProjectCustomData[] => {
    return clients.map(({ id, name }) => ({ value: id, label: name }));
  };

  const updateCurrentProjectAndNavigateToDetail = ({ id, type }: ProjectListResponseDataModel) => {
    updateSelectedProject({ projectId: id, type });
  };

  const getProjectsListByClientId = async (clientId: string) => {
    setIsLoadingProjectsList(true);
    try {
      const response = await GetProjectsListByClientId(clientId);
      if (!!response?.data?.length) {
        const transformedProjects = transformProjectsListData(response.data);
        setProjectsList(transformedProjects);
        setProjectsDataList(response.data);
        response.data.length === 1 && updateCurrentProjectAndNavigateToDetail(response.data[0]);
        response.data.length === 1 &&
          setCurrentClientIdAndProjectId({ projectId: response.data[0].id, projectType: response.data[0].type });
        response.data.length > 1 && !!currentProject && filterProjectAndValidateTabsVisibility(response.data);
      }
    } catch (error) {
      messageAtom.error(t('_ACCESSES_CLIENT_AND_PROJECT_PROJECT_ERROR'), 5);
    } finally {
      setIsLoadingProjectsList(false);
    }
  };

  const resetInitialValues = () => {
    setShowEmptyMessage(true);
    setCurrentClient(null);
    setCurrentProject(null);
    setAreTabsVisible(false);
  };

  const updateRoute = ({ client, project, projectType, tab }: RouteManagerModel) => {
    setShowEmptyMessage(false);
    client && setCurrentClient(client);
    project && setCurrentProject(project);
    const verticalType = checkUrlVerticalType();
    const type = (!!projectType ? projectType : verticalType) as ProjectVerticalsEnum;
    !!type && !!tab && verticalTabManager[type](tab);
    !!client && !!project && !!type && navigateToAccessesListByType({ clientId: client, projectId: project, type, tab });
  };

  const checkBasicRoute = () => {
    const { clientId, projectId, projectType, tab } = getLastClientIdAndProjectId();
    !!clientId && !!projectId && updateRoute({ client: clientId, project: projectId, projectType, tab });
    !clientId && !projectId && resetInitialValues();
  };

  const updateValues = ({ client, project }: RouteManagerModel) => {
    setShowEmptyMessage(false);
    client && setCurrentClient(client);
    project && setCurrentProject(project);
  };

  const updateHotelTab = (tab: string) => {
    const hotelTabsValues = Object.values(HotelAccessesTabType);
    const isHotelTab = hotelTabsValues.includes(tab as HotelAccessesTabType);
    isHotelTab && setSelectedHotelTab(tab as HotelAccessesTabType);
  };

  const updateOfficeTab = (tab: string) => {
    const officeTabsValues = Object.values(OfficeAccessesTabType);
    const isOfficeTab = officeTabsValues.includes(tab as OfficeAccessesTabType);
    isOfficeTab && setSelectedOfficeTab(tab as OfficeAccessesTabType);
  };

  const verticalTabManager = {
    [ProjectVerticalsEnum.BUILDING]: () => null,
    [ProjectVerticalsEnum.HOME]: () => null,
    [ProjectVerticalsEnum.HOTEL_ROOM]: () => null,
    [ProjectVerticalsEnum.HOTEL]: updateHotelTab,
    [ProjectVerticalsEnum.OFFICE]: updateOfficeTab,
    [ProjectVerticalsEnum.PARKING_SLOT]: () => null,
    [ProjectVerticalsEnum.RENT]: () => null,
    [ProjectVerticalsEnum.VACATIONAL_RENTAL]: () => null,
  };

  const checkUrlVerticalType = (): string | undefined => {
    const splitRoute = window.location.pathname.split('/');
    return splitRoute[7]?.toUpperCase();
  };

  const updateValuesAndTabs = ({ client, project, tab }: RouteManagerModel) => {
    setShowEmptyMessage(false);
    client && setCurrentClient(client);
    project && setCurrentProject(project);
    const verticalType = checkUrlVerticalType();
    !!verticalType && !!tab && verticalTabManager[verticalType as ProjectVerticalsEnum](tab);
  };

  const routeManager = {
    [RouteSegmentType.DEFAULT_SEGMENT]: checkBasicRoute,
    [RouteSegmentType.LAST_CLIENT_AND_PROJECT]: updateValues,
    [RouteSegmentType.CLIENT_AND_PROJECT_SEGMENT]: updateValues,
    [RouteSegmentType.ACCESS_LIST_SEGMENT]: updateValuesAndTabs,
    [RouteSegmentType.ACCESS_DETAIL_SEGMENT]: updateValues,
  };

  const navigateToTabDetail = (verticalType: ProjectVerticalsEnum, tab: string) => {
    if (currentClient && currentProject) {
      const data: NavigationDataModel = {
        clientId: currentClient,
        projectId: currentProject,
        page: TABLE_PAGINATION_PAGE_DEFAULT,
        size: TABLE_PAGINATION_SIZE_DEFAULT,
      };
      const route = accessesRoomsListNavigationManager[verticalType]({ tab, data });
      history.push(route);
    }
  };

  const onSelectTab = (tab: string) => {
    const verticalType = checkUrlVerticalType() as ProjectVerticalsEnum;
    verticalTabManager[verticalType](tab);
    navigateToTabDetail(verticalType, tab);
    setCurrentClientIdAndProjectId({ projectType: verticalType, tab });
  };

  useEffect(() => {
    const splitRoute = window.location.pathname.split('/');
    const index = splitRoute.length;
    const { tab } = getLastClientIdAndProjectId();
    const routeManagerParams: RouteManagerModel = {
      client: splitRoute[4],
      project: splitRoute[6],
      tab: tab || splitRoute[8],
    };
    routeManager[index as RouteSegmentType](routeManagerParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    !!currentClient && getProjectsListByClientId(currentClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  useEffect(() => {
    !!userProfile && getClientsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile]);

  return {
    title,
    currentClient,
    currentProject,
    projectType,
    clientPlaceholderText,
    projectPlaceholderText,
    emptyText,
    clientsList,
    projectsList,
    selectedHotelTab,
    selectedOfficeTab,
    tabsTranslations,
    onSelectClient,
    onSelectProject,
    onSelectTab,
    loadingClientsList: isLoadingClientsList,
    loadingProjectsList: isLoadingProjectsList,
    showEmptyMessage,
    tabsVisible: areTabsVisible,
  };
};
