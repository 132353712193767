import { ChartConfiguration } from 'chart.js';

export const configuration: ChartConfiguration = {
  type: 'bar',
  data: {
    labels: [] as string[],
    datasets: [
      {
        barThickness: 'flex',
        borderRadius: 4,
        data: [] as number[],
        backgroundColor: ['#96C0C4'],
        borderColor: ['#96C0C4'],
        borderWidth: 1,
      },
    ],
  },
  options: {
    responsive: true,
    animation: {
      duration: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: '#CCCCCC',
          display: true,
          borderDash: [3],
          drawBorder: false,
          drawTicks: false,
        },
        ticks: {
          color: '#888888',
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#888888',
        },
      },
    },
  },
};
