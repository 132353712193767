import Paper from 'components/atoms/Paper';
import AlfredTableTemplate from 'components/template/AlfredTableTemplate';
import { useProjectAssets } from './useProjectAssets';
import { ProjectAssetModel } from 'core/domain/project/model/projectModel';
import { ProjectAssetsColumns } from 'components/organisms/ProjectAssetColumns';

const TabAssets = () => {
  const {
    assets,
    currentPage,
    pageSize,
    totalPages,
    assetGatewayConnectionStatusTranslations,
    assetOccupationTranslations,
    onSelectAsset,
    onChangePage,
    onSearch,
    onOpenLink,
    loadingAssets,
  } = useProjectAssets();
  const { assetNotOccupied, assetOccupied } = assetOccupationTranslations;
  const { connected, disconnected, noLinkedGateways, defaultText } = assetGatewayConnectionStatusTranslations;
  const assetsColumns = ProjectAssetsColumns({
    onClick: onSelectAsset,
    onOpenLink,
    assetOccupiedText: assetOccupied,
    assetNotOccupiedText: assetNotOccupied,
    assetConnectedText: connected,
    assetDisconnectedText: disconnected,
    assetNoLinkedGatewayText: noLinkedGateways,
    assetDefaultText: defaultText,
  });

  return (
    <Paper>
      <AlfredTableTemplate<ProjectAssetModel>
        actionSection={<></>}
        columns={assetsColumns}
        onRowClick={onSelectAsset}
        data={assets}
        totalPages={totalPages}
        isCharging={loadingAssets}
        pageSize={pageSize}
        pageCurrent={currentPage}
        onPagination={onChangePage}
        onSearch={onSearch}
      />
    </Paper >
  );
};

export default TabAssets;
