import { FC } from 'react';
import Error from 'assets/svg/icon/Error';
import { OccupationDashboardProps } from 'components/pages/DashboardPage/resources/models';
import './styles.scss';

const DashboardOccupationErrorTemplate: FC<OccupationDashboardProps> = ({ literals }) => {
  const { title, titleTimePeriod, errorTitle, errorDescription } = literals;

  return (
    <div className='OccupationErrorTemplate__container'>
      <div className='OccupationErrorTemplate__header'>
        <p className='OccupationErrorTemplate__title'>{title}</p>
        <p className='OccupationErrorTemplate__subtitle'>{titleTimePeriod}</p>
      </div>
      <div className='OccupationErrorTemplate__message-container'>
        <div className='OccupationErrorTemplate__message-content'>
          <Error />
          <p className='OccupationErrorTemplate__message-title'>{errorTitle}</p>
          <p className='OccupationErrorTemplate__message-description'>{errorDescription}</p>
        </div>
      </div>
    </div>
  );
}

export default DashboardOccupationErrorTemplate;