import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Asset } from 'models/assets.model';
import { User } from 'models/users.model';
import { AssetsColumns } from 'components/molecules/AssetsColumns';
import { ClientsColumns } from 'components/pages/SettingsPage/ClientsColumns';
import { ProjectsColumns } from 'components/pages/ProjectsPage/ProjectsColumns';
import { UsersColumns } from 'components/pages/UsersPage/UsersColumns';
import { ClientModel } from 'core/domain/client/models/clientEntityModel';
import { getAssetPathById } from 'components/pages/App/routes/assets/config';
import { getProjectPathById } from 'components/pages/App/routes/projects/config';
import { getUserPathById } from 'components/pages/App/routes/users/config';
import Block from './Block';
import { ProjectModel } from 'core/domain/project/model/projectModel';

interface ResultsProps {
  assets: Asset[];
  clients: ClientModel[];
  loading: boolean;
  projects: ProjectModel[];
  users: User[];
}

const Results: FC<ResultsProps> = ({ assets, clients, loading, projects, users }) => {
  const { t } = useTranslation('global');
  const history = useHistory();

  const clientsColumns = ClientsColumns();
  const usersColumns = UsersColumns();

  const handleRowClickProject = ({ id }: ProjectModel) => history.push(getProjectPathById({ projectId: id }));

  const handleRowClickUser = (user: User) => history.push(getUserPathById(user.id));

  const handleRowClickAsset = (asset: Asset) => history.push(getAssetPathById(asset.id));

  const onOpenLink = (id: string, event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const url = `${window.location.origin}/admin/assets/${id}/start`;
    window.open(url, '_blank');
  }

  const assetsColumns = AssetsColumns(handleRowClickAsset, onOpenLink);
  const projectsColumns = ProjectsColumns();

  return (
    <>
      <Block<Asset>
        columns={assetsColumns}
        dataSource={assets}
        key='assets'
        loading={loading}
        onRowClick={handleRowClickAsset}
        title={t('instalations')}
      />
      <Block<User>
        columns={usersColumns}
        dataSource={users}
        key='users'
        loading={loading}
        onRowClick={handleRowClickUser}
        title={t('users')}
      />
      <Block<ClientModel> columns={clientsColumns} dataSource={clients} key='clients' loading={loading} title={t('companies')} />
      <Block<ProjectModel>
        columns={projectsColumns}
        dataSource={projects}
        key='projects'
        loading={loading}
        onRowClick={handleRowClickProject}
        title={t('communities')}
      />
    </>
  );
};

export default Results;
