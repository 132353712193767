import { useEffect, useReducer } from 'react';
import {
  dashboardInitialState,
  formatUnits,
  getLabelsLiteralsActiveGateways,
  getLabelsLiteralsUsersListStatistics,
} from './utils';
import {
  ActiveGatewaysDashboardProps,
  ActiveUsersDashboardProps,
  Dashboard,
  DashboardInitialStatesModel,
  OccupationDashboardProps,
  SupportTicketsDashboardProps,
} from './models';
import { SupportTicketsStatisticsModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import { GetActiveUsersOccupationStatistics } from 'core/domain/assets/repositories/getAssetsOccupationStatistics';
import { GetUsersListUnstructuredStatistics } from 'core/domain/users/repositories/getUsersListUnstructuredStatistics';
import { GetSupportTicketsStatistics } from 'core/domain/supportTickets/repositories/getSupportTicketsStatistics';
import { GetSupportTicketsList } from 'core/domain/supportTickets/repositories/getSupportTicketsList';
import { ROUTE_PATH_SUPPORT_TICKETS } from 'components/pages/App/routes/supportTickets/config';
import { useHistory } from 'react-router-dom';
import { GetActiveGatewaysStatistics } from 'core/domain/gateways/repositories/getActiveGatewaysStatistics';

export interface Action {
  key: string;
  payload: OccupationDashboardProps |
    ActiveUsersDashboardProps |
    SupportTicketsDashboardProps |
    ActiveGatewaysDashboardProps;
}

const reducer = (
  state: DashboardInitialStatesModel,
  action: Action,
) => ({ ...state, [action.key]: action.payload });

export const useDashboard = () => {
  const history = useHistory();
  const [{
    occupation,
    activeUsers,
    supportTickets,
    activeGateways,
  }, dispatch] = useReducer(reducer, dashboardInitialState);


  const getActiveUsersOccupationStatistics = async () => {
    try {
      const statistics = await GetActiveUsersOccupationStatistics();
  
      dispatch({
        key: Dashboard.OCCUPATION,
        payload: {
          ...occupation,
          assetsLinked: statistics.assetsLinkedToUsers,
          assetsNotLinked: statistics.assetsNotLinkedToUsers,
          isLoading: false,
          isError: false,
        }
      })
    } catch (error) {
      dispatch({
        key: Dashboard.OCCUPATION,
        payload: {
          ...occupation,
          isError: true,
          isLoading: false,
        }
      })
    }
  }

  const getUsersListStatistics = async () => {
    try {
      const usersListStatistics = await GetUsersListUnstructuredStatistics();

      usersListStatistics && (
        dispatch({
        key: Dashboard.ACTIVE_USERS,
        payload: {
          ...activeUsers,
          statistics: {
            ...usersListStatistics,
            labels: getLabelsLiteralsUsersListStatistics(usersListStatistics.labels)
          },
          isLoading: false,
          isError: false,
        }
        })
      )
    } catch (error) {
      dispatch({
        key: Dashboard.ACTIVE_USERS,
        payload: {
          ...activeUsers,
          isError: true,
          isLoading: false,
        }
      })
    }
  };

  const formatSupportTicketsStatisticsUnits = (tickets?: SupportTicketsStatisticsModel): SupportTicketsStatisticsModel | undefined => {
    const ticketsClosedUnits = formatUnits(tickets?.ticketsClosedTimeAverageUnits);
    const firstResponseUnits = formatUnits(tickets?.firstResponseTimeAverageUnits);
    return (
      tickets && {
        ...tickets,
        ticketsClosedTimeAverageUnits: ticketsClosedUnits,
        firstResponseTimeAverageUnits: firstResponseUnits,
      }
    );
  };

  const getSupportTicketsStatistics = async () => {
    try {
      const ticketsStatistics = await GetSupportTicketsStatistics();
      const ticketsStatisticsWithFormattedUnits = formatSupportTicketsStatisticsUnits(ticketsStatistics);
      const { totalValues } = await GetSupportTicketsList({});

      ticketsStatisticsWithFormattedUnits && (
        dispatch({
          key: Dashboard.SUPPORT_TICKETS,
          payload: {
            ...supportTickets,
            statistics: ticketsStatisticsWithFormattedUnits,
            openTickets: totalValues?.totalOpenSupportTickets || 0,
            isError: false,
            isLoading: false,
          }
        })
      )
    } catch (error) {
      dispatch({
        key: Dashboard.SUPPORT_TICKETS,
        payload: {
          ...supportTickets,
          isError: true,
          isLoading: false,
        }
      })
    }
  };

  const getActiveGatewaysStatistics = async () => {
    try {
      const activeGatewaysStatistics = await GetActiveGatewaysStatistics();

      if (!!activeGatewaysStatistics) {
        const labels: string[] = getLabelsLiteralsActiveGateways(activeGatewaysStatistics.gateways);
        const values: number[] = activeGatewaysStatistics.gateways.map(({ value }) => value);

        dispatch({
          key: Dashboard.ACTIVE_GATEWAYS,
          payload: {
            ...activeGateways,
            statistics: { ...activeGatewaysStatistics, labels, values },
            isError: false,
            isLoading: false,
          }
        })
      }
    } catch (error) {
      dispatch({
        key: Dashboard.ACTIVE_GATEWAYS,
        payload: {
          ...activeGateways,
          isError: true,
          isLoading: false,
        }
      })
    }
  };

  const setIsLoading = () => {
    dispatch({
      key: Dashboard.OCCUPATION,
      payload: {
        ...occupation,
        isLoading: true,
      }
    });

    dispatch({
      key: Dashboard.ACTIVE_USERS,
      payload: {
        ...activeUsers,
        isLoading: true,
      }
    });

    dispatch({
      key: Dashboard.SUPPORT_TICKETS,
      payload: {
        ...supportTickets,
        isLoading: true,
      }
    });

    dispatch({
      key: Dashboard.ACTIVE_GATEWAYS,
      payload: {
        ...activeGateways,
        isLoading: true,
      }
    });
  }

  useEffect(() => {
    setIsLoading();
    getActiveUsersOccupationStatistics();
    getUsersListStatistics();
    getSupportTicketsStatistics();
    getActiveGatewaysStatistics();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickOpenedSupportTickets = () => {
    history.push(ROUTE_PATH_SUPPORT_TICKETS);
  };

  supportTickets.onClickOpened = onClickOpenedSupportTickets;

  return {
    occupation,
    activeUsers,
    supportTickets,
    activeGateways,
  }
}
