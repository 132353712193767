import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputRef } from 'antd';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { getAsset } from 'services/api/assets';
import { GetDevicesLists } from 'core/domain/devices/repositories/getDevicesList';
import { DeviceModel } from 'core/domain/devices/models';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { useMessage } from 'hooks/useMessage';
import { ExpandedPeripheralDevicesTranslations } from 'components/organisms/PeripheralDevices/ExpandedColumnsPeripheralsDevices';
import { ColumnSearchTranslationsType, DeviceDataIndex } from 'components/organisms/PeripheralDevices/SearchPeripheralColumns';

export const useTabDevices = () => {
  const { t } = useTranslation();
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const { assetId } = useParams<{ assetId: string }>();
  const [loading, setLoading] = useState(true);
  const [gatewayId, setGatewayId] = useState<string>('');
  const [devices, setDevices] = useState<DeviceModel[]>([]);
  const [searchedColumn, setSearchedColumn] = useState<string>('');

  const searchInput = useRef<InputRef>(null);

  const peripheralsTitle: string = t('peripheral');

  const peripheralExpandedColumnsTranslations: ExpandedPeripheralDevicesTranslations = {
    nameText: t('name'),
    roomText: t('room'),
    typeText: t('type'),
    usageText: t('usage'),
    notReportedText: t('not_reported'),
  };

  const columnSearchTranslations: ColumnSearchTranslationsType = {
    closeButtonText: t('close'),
    resetButtonText: t('_RESET'),
    searchButtonText: t('_SEARCH'),
    placeholder: t('_PLACEHOLDER_GENERAL'),
    notReportedText: t('not_reported'),
  };

  const onSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    confirm({ closeDropdown: false });
    setSearchedColumn(dataIndex);
  };

  const onReset = (clearFilters: () => void, confirm: (param?: FilterConfirmProps) => void, dataIndex: DeviceDataIndex) => {
    clearFilters();
    onSearch([''], confirm, dataIndex);
  };

  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await getAsset(assetId);
      setGatewayId(data.gateways_qrs[0].gateway_id);
      const devicesData = await GetDevicesLists({ host, token, assetId });
      setDevices(devicesData);
    } catch (error) {
      setMessageError({ description: t('_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  return {
    devices,
    gatewayId,
    peripheralsTitle,
    peripheralExpandedColumnsTranslations,
    searchInput,
    columnSearchTranslations,
    searchedColumn,
    onSearch,
    onReset,
    loading,
  };
};
