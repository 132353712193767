import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { RemoveDeviceHelpParams } from '../models';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = ({ host, deviceId, gatewayId }: {
  host: string,
  deviceId: string,
  gatewayId: string,
}) => {
  return `${host}/devices/help/${deviceId}/gateways/${gatewayId}`;
};

export const RemoveDeviceHelpData = async ({
  deviceId,
  gatewayId,
  host,
  token
}: RemoveDeviceHelpParams): Promise<void> => {
  try {
    const url = getUrl({ host, deviceId, gatewayId });

    const options: AxiosRequestConfig = {
      url,
      method: 'DELETE',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    await axios(options) as AxiosResponse<void>;
  } catch (error) {
    throw getErrorFormat(error);
  }
}