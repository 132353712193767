import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetDeviceHelpData } from 'core/domain/device/repository/getDeviceHelpData';
import { CreateDeviceHelpData } from 'core/domain/device/repository/createDeviceHelpData';
import { UpdateDeviceHelpData } from 'core/domain/device/repository/updateDeviceHelpData';
import { RemoveDeviceHelpData } from 'core/domain/device/repository/removeDeviceHelpData';
import { useEnvironment } from 'hooks/useEnvironment';
import { useUserSession } from 'hooks/useUserSession';
import { messageAtom } from 'components/atoms/MessageAtom';
import {
  getInitialDeviceHelpDescriptionData,
  getInitialDeviceHelpTitleData,
  resetDeviceHelpDescriptionData,
  resetDeviceHelpTitleData,
} from './utils';
import {
  Action,
  DeviceHelpDrawerInitialStatesModel,
  DrawerItem,
} from './models';

export const deviceHelpDrawerInitialState = {
  [DrawerItem.DEVICE_HELP_FORM_TITLE]: getInitialDeviceHelpTitleData(),
  [DrawerItem.DEVICE_HELP_FORM_DESCRIPTION]: getInitialDeviceHelpDescriptionData(),
}

export const reducer = (
  state: DeviceHelpDrawerInitialStatesModel,
  action: Action,
) => ({ ...state, [action.key]: action.payload });

export const useDeviceHelpDrawer = () => {
  const [{
    deviceHelpFormDescriptionState,
    deviceHelpFormTitleState,
  }, dispatch] = useReducer(reducer, deviceHelpDrawerInitialState);
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(true);
  const [isActiveInfo, setIsActiveInfo] = useState<boolean>(false);
  const [isLoadingChargeDrawer, setIsLoadingChargeDrawer] = useState<boolean>(true);
  const [deviceGatewayId, setDeviceGatewayId] = useState<string>('')
  const [deviceId, setDeviceId] = useState<string>('')
  const [deviceName, setDeviceName] = useState<string>('')
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = useState<boolean>(true);
  const { t } = useTranslation();

  const title: string = `${t('_ACCESS_DETAIL_HELP_DRAWER_TITLE')} ${deviceName}`;
  const subtitle: string = t('_ACCESS_DETAIL_HELP_DRAWER_SUBTITLE');
  const saveTextButton: string = t('_ACCESS_DETAIL_HELP_DRAWER_SAVE_BUTTON');
  const closeTextButton: string = t('_ACCESS_DETAIL_HELP_DRAWER_CLOSE_BUTTON');

  const getDeviceHelpData = ({ deviceGatewayId, deviceId }: {
    deviceGatewayId: string,
    deviceId: string,
  }) => {
    GetDeviceHelpData({
      host,
      token,
      gatewayId: deviceGatewayId,
      deviceId,
    })
      .then(({ title, description }) => {
        dispatch({
          key: DrawerItem.DEVICE_HELP_FORM_TITLE,
          payload: {
            ...deviceHelpFormTitleState,
            defaultValue: title,
            isDisabled: false,
          }
        });
        dispatch({
          key: DrawerItem.DEVICE_HELP_FORM_DESCRIPTION,
          payload: {
            ...deviceHelpFormDescriptionState,
            defaultValue: description,
            isDisabled: false,
          }
        });
        setIsEdit(true);
        setIsVisible(true);
        setIsDisabled(false);
        setIsActiveInfo(true);
        setIsDisabledSaveButton(false);
      })
      .catch((error) => {
        if (error.code === 404) {
          setIsEdit(false);
          setIsVisible(true);
          setIsDisabled(false);
          setIsActiveInfo(false);
        }

        if (error.code !== 404) {
          messageAtom.error(t('_ACCESS_DETAIL_HELP_DRAWER_ERROR_GET'), 5);
          setIsEdit(false);
          setIsVisible(true);
          setIsDisabled(false);
          setIsActiveInfo(false);
        }
      })
      .finally(() => {
        setIsLoadingChargeDrawer(false);
        setIsLoadingSave(false);
      })
  };

  const createDeviceHelpData = () => {
    CreateDeviceHelpData({
      host,
      token,
      gatewayId: deviceGatewayId,
      deviceId,
      title: deviceHelpFormTitleState.defaultValue,
      description: deviceHelpFormDescriptionState.defaultValue,
    })
      .then(() => {
        messageAtom.success(t('_ACCESS_DETAIL_HELP_DRAWER_SUCCESS_SET'), 5);
        setIsVisible(false);
        setTimeout(() => {
          dispatch({
            key: DrawerItem.DEVICE_HELP_FORM_TITLE,
            payload: {
              ...resetDeviceHelpTitleData(),
            }
          });
          dispatch({
            key: DrawerItem.DEVICE_HELP_FORM_DESCRIPTION,
            payload: {
              ...resetDeviceHelpDescriptionData(),
            }
          });
        }, 1000)
      })
      .catch(() => {
        messageAtom.error(t('_ACCESS_DETAIL_HELP_DRAWER_ERROR_SET'), 5);
      })
      .finally(() => {
        setIsLoadingSave(false);
        setIsDisabled(false);
      });
  };

  const updateDeviceHelpData = () => {
    UpdateDeviceHelpData({
      host,
      token,
      gatewayId: deviceGatewayId,
      deviceId,
      title: deviceHelpFormTitleState.defaultValue,
      description: deviceHelpFormDescriptionState.defaultValue,
    })
      .then(() => {
        messageAtom.success(t('_ACCESS_DETAIL_HELP_DRAWER_SUCCESS_SAVE'), 5);
        setIsVisible(false);
        setTimeout(() => {
          dispatch({
            key: DrawerItem.DEVICE_HELP_FORM_TITLE,
            payload: {
              ...resetDeviceHelpTitleData(),
            }
          });
          dispatch({
            key: DrawerItem.DEVICE_HELP_FORM_DESCRIPTION,
            payload: {
              ...resetDeviceHelpDescriptionData(),
            }
          });
        }, 1000)
      })
      .catch(() => {
        messageAtom.error(t('_ACCESS_DETAIL_HELP_DRAWER_ERROR_EDIT'), 5);
      })
      .finally(() => {
        setIsLoadingSave(false);
        setIsDisabled(false);
      });
  };
  
  const removeDeviceHelpData = () => {
    RemoveDeviceHelpData({
      host,
      token,
      gatewayId: deviceGatewayId,
      deviceId,
    })
      .then(() => {
        messageAtom.success(t('_ACCESS_DETAIL_HELP_DRAWER_SUCCESS_REMOVE'), 5);
        setIsVisible(false);
        setTimeout(() => {
          dispatch({
            key: DrawerItem.DEVICE_HELP_FORM_TITLE,
            payload: {
              ...resetDeviceHelpTitleData(),
            }
          });
          dispatch({
            key: DrawerItem.DEVICE_HELP_FORM_DESCRIPTION,
            payload: {
              ...resetDeviceHelpDescriptionData(),
            }
          });
          setIsDisabledSaveButton(true);
        }, 1000)
      })
      .catch((error) => {
        if (error.code === 404) {
          setIsVisible(false);
        }

        if (error.code !== 404) {
          messageAtom.error(t('_ACCESS_DETAIL_HELP_DRAWER_ERROR_REMOVE'), 5);
        }
      })
      .finally(() => {
        setIsLoadingSave(false);
        setIsDisabled(false);
      });
  };

  const setErrorDeviceHelp = () => {
    dispatch({
      key: DrawerItem.DEVICE_HELP_FORM_TITLE,
      payload: {
        ...deviceHelpFormTitleState,
        isError: true,
      }
    });
    dispatch({
      key: DrawerItem.DEVICE_HELP_FORM_DESCRIPTION,
      payload: {
        ...deviceHelpFormDescriptionState,
        isError: true,
      }
    });
    setIsDisabled(false);
    setIsLoadingSave(false);
  };

  const createDeviceHelp = (isEmptyTitle: boolean, isEmptyDescription: boolean) => {
    if (!isEmptyTitle || !isEmptyDescription) createDeviceHelpData();
    if (isEmptyTitle && isEmptyDescription) setErrorDeviceHelp();
  };

  const editDeviceHelp = (isEmptyTitle: boolean, isEmptyDescription: boolean) => {
    if (!isEmptyTitle || !isEmptyDescription) updateDeviceHelpData();
    if (isEmptyTitle && isEmptyDescription) setErrorDeviceHelp();
  };
  
  const onSave = () => {
    const isEmptyTitle = !deviceHelpFormTitleState.defaultValue.trim().length;
    const isEmptyDescription = !deviceHelpFormDescriptionState.defaultValue.trim().length;
    setIsLoadingSave(true);
    setIsDisabled(true);

    if (!isActiveInfo) removeDeviceHelpData();
    if (isActiveInfo) {
      createDeviceHelp(isEmptyTitle, isEmptyDescription);
    }
  };

  const onEdit = () => {
    const isEmptyTitle = !deviceHelpFormTitleState.defaultValue.trim().length;
    const isEmptyDescription = !deviceHelpFormDescriptionState.defaultValue.trim().length;
    setIsLoadingSave(true);
    setIsDisabled(true);

    if (!isActiveInfo) removeDeviceHelpData();
    if (isActiveInfo) {
      editDeviceHelp(isEmptyTitle, isEmptyDescription);
    }
  };

  deviceHelpFormTitleState.onChange = (value: string) => {
    dispatch({
      key: DrawerItem.DEVICE_HELP_FORM_TITLE,
      payload: {
        ...deviceHelpFormTitleState,
        defaultValue: value,
      }
    });
  };

  deviceHelpFormDescriptionState.onChange = (value: string) => {
    dispatch({
      key: DrawerItem.DEVICE_HELP_FORM_DESCRIPTION,
      payload: {
        ...deviceHelpFormDescriptionState,
        defaultValue: value,
      }
    });
  };

  const onClose = () => {
    setIsVisible(false);
    dispatch({
      key: DrawerItem.DEVICE_HELP_FORM_TITLE,
      payload: {
        ...resetDeviceHelpTitleData(),
      }
    });
    dispatch({
      key: DrawerItem.DEVICE_HELP_FORM_DESCRIPTION,
      payload: {
        ...resetDeviceHelpDescriptionData(),
      }
    });
  };

  const onSetIsLoadingChargeDrawer = (value: boolean) => setIsLoadingChargeDrawer(value);
  const onSetIsLoadingSave = (value: boolean) => setIsLoadingSave(value);
  const onSetIsVisible = (value: boolean) => setIsVisible(value);

  const onOpen = ({ deviceGatewayId, deviceId, deviceName }: {
    deviceGatewayId: string;
    deviceId: string
    deviceName: string;
  }) => {
    onSetIsLoadingChargeDrawer(true);
    onSetIsLoadingSave(true);
    onSetIsVisible(true);
    setDeviceId(deviceId);
    setDeviceGatewayId(deviceGatewayId);
    setDeviceName(deviceName);
    getDeviceHelpData({
      deviceGatewayId,
      deviceId,
    });
  }

  const onActiveInfo = (isActiveInfo: boolean) => {
    setIsActiveInfo(isActiveInfo);
    setIsDisabledSaveButton(false);
    dispatch({
      key: DrawerItem.DEVICE_HELP_FORM_TITLE,
      payload: {
        ...deviceHelpFormTitleState,
        isDisabled: !isActiveInfo,
        isError: false,
      }
    });
    dispatch({
      key: DrawerItem.DEVICE_HELP_FORM_DESCRIPTION,
      payload: {
        ...deviceHelpFormDescriptionState,
        isDisabled: !isActiveInfo,
        isError: false,
      }
    });
  };

  return {
    controlsDeviceHelpDrawer: {
      title,
      subtitle,
      saveTextButton,
      closeTextButton,
      onActiveInfo,
      onClose,
      onOpen,
      onSave,
      onEdit,
      isActiveInfo,
      isLoadingSave,
      isLoadingCharge: isLoadingChargeDrawer,
      isEdit,
      isVisible,
      isDisabled,
      isDisabledSaveButton,
    },
    formDeviceHelpDrawer: {
      title: deviceHelpFormTitleState,
      description: deviceHelpFormDescriptionState,
    }
  }
}