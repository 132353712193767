import { Typography } from 'antd';
import ContextualMenu from 'components/atoms/ContextualMenu';
import MenuAtom from 'components/atoms/MenuAtom';
import { AlfredTableColumn } from 'components/organisms/AlfredTable';
import { AccessDeviceModel, AccessDeviceTypeModel } from 'core/domain/access/models';

export interface OfficeAccessDevicesListColumnsLiteralsModel {
  nameTitle: string;
  roomTitle: string;
  areaTitle: string;
  batteryTitle: string;
  deviceTypeTitle: string;
  actionsTitle: string;
  openAccessDeviceText: string;
}

export enum OfficeAccessDevicesListColumnsOrderType {
  ASCEND = 'ascend',
  DESCEND = 'descend',
}

export interface Props {
  literals: OfficeAccessDevicesListColumnsLiteralsModel,
  onOpenAccessDevice: (accessDevice: AccessDeviceModel) => void;
  getDeviceTypeTranslation: (deviceType: AccessDeviceTypeModel) => string;
}

export const OfficeAccessDevicesListColumns = ({ literals, onOpenAccessDevice, getDeviceTypeTranslation }: Props): AlfredTableColumn<AccessDeviceModel>[] => {
  const { nameTitle, roomTitle, areaTitle, batteryTitle, deviceTypeTitle, actionsTitle, openAccessDeviceText } = literals;

  return [
    {
      title: nameTitle,
      key: 'name',
      width: '20%',
      render: (text, { name }) => <Typography.Text>{!!name ? name : '-'}</Typography.Text>,
    },
    {
      title: roomTitle,
      key: 'room',
      width: '15%',
      render: (text, { room }) => <Typography.Text>{!!room ? room : '-'}</Typography.Text>,
    },
    {
      title: areaTitle,
      key: 'area',
      width: '20%',
      render: (text, { asset }) => <Typography.Text>{!!asset ? asset.alias : '-'}</Typography.Text>,
    },
    {
      title: batteryTitle,
      key: 'battery_level',
      width: '10%',
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      render: (text, { batteryLevel }) => <Typography.Text>{!!batteryLevel ? `${batteryLevel}%` : '-'}</Typography.Text>,
    },
    {
      title: deviceTypeTitle,
      key: 'device_type',
      width: '25%',
      render: (text, { deviceType }) => <Typography.Text>{!!deviceType ? getDeviceTypeTranslation(deviceType) : '-'}</Typography.Text>,
    },
    {
      title: actionsTitle,
      key: 'actions',
      width: '10%',
      render: (text, accessDevice) => <ContextualMenu menu={(
        <MenuAtom
          items={[
            {
              key: 'open-access-device',
              label: openAccessDeviceText,
              onClick: () => onOpenAccessDevice(accessDevice),
            },
          ]}
        />
      )} />,
    },
  ];
};
