import { FC } from 'react';
import CountUp from 'react-countup';
import { SupportTicketsDashboardProps } from 'components/pages/DashboardPage/resources/models';
import './styles.scss';

const DashboardSupportTicketsDefaultTemplate: FC<SupportTicketsDashboardProps> = ({
  statistics, onClickOpened, literals, openTickets
}) => {
  const {
    ticketsClosed,
    ticketsClosedTimeAverage,
    ticketsClosedTimeAverageUnits,
    firstResponseTimeAverage,
    firstResponseTimeAverageUnits,
    ticketsReceived,
    ticketsRevoked,
    ticketsInProgress,
  } = statistics;

  return (
    <div className='SupportTicketsTemplate__container'>
      <div className='SupportTicketsTemplate__header'>
        <div>
          <p className='SupportTicketsTemplate__title'>{
            literals.title}
          </p>
          <p className='SupportTicketsTemplate__subtitle'>
            {literals.titleTimePeriod}
          </p>
        </div>
        <div onClick={onClickOpened}>
          <p className='SupportTicketsTemplate__openTickets'>
            {`${literals.openTickets}: ${openTickets}`}
          </p>
        </div>
      </div>
      <div className='SupportTicketsTemplate__chart-container'>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {literals.ticketsClosedTimeAverage}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            {`${ticketsClosedTimeAverage}${ticketsClosedTimeAverageUnits}`}
          </span>
        </div>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {literals.firstResponseTimeAverage}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            {`${firstResponseTimeAverage}${firstResponseTimeAverageUnits}`}
          </span>
        </div>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {literals.ticketsReceived}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsReceived} duration={2.75} useEasing />
          </span>
        </div>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {literals.ticketsClosed}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsClosed} duration={2.75} useEasing />
          </span>
        </div>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {literals.ticketsInProgress}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsInProgress} duration={2.75} useEasing />
          </span>
        </div>
        <div className='SupportTicketsTemplate__chart-element'>
          <p className='SupportTicketsTemplate__chart-element-title'>
            {literals.ticketsRevoked}
          </p>
          <span className='SupportTicketsTemplate__chart-element-data'>
            <CountUp start={0} end={ticketsRevoked} duration={2.75} useEasing />
          </span>
        </div>
      </div>
    </div>
  );
}

export default DashboardSupportTicketsDefaultTemplate;