import { FC } from 'react';
import { DeviceHelpTextInputProps } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/useDeviceHelpDrawer/models';
import SwitchAtom from 'components/atoms/SwitchAtom';
import TextArea from 'components/atoms/TextArea';
import FormAtom from 'components/atoms/FormAtom';
import InputAtom from 'components/atoms/InputAtom';
import WebappPreview from './WebappPreview';
import './styles.scss';

interface Props {
  title: string;
  formDeviceHelpDrawerDescriptionState: DeviceHelpTextInputProps;
  formDeviceHelpDrawerTitleState: DeviceHelpTextInputProps;
  onEditForm: (isActive: boolean) => void;
  isEditForm: boolean;
  isVisiblePreviewInfo: boolean;
}

const DeviceHelpTemplate: FC<Props> = ({
  title,
  formDeviceHelpDrawerTitleState,
  formDeviceHelpDrawerDescriptionState,
  onEditForm,
  isEditForm,
  isVisiblePreviewInfo,
}) => {
  return (
    <main>
      <div className='DeviceHelpTemplate__active-toggle'>
        <p>{title}</p>
        <SwitchAtom
          checked={isEditForm}
          onChange={() => onEditForm(!isEditForm)}
        />
      </div>
      <FormAtom layout={'vertical'}>
        <FormAtom.Item
          label={formDeviceHelpDrawerTitleState.label}
          validateStatus={formDeviceHelpDrawerTitleState.isError ? 'error' : undefined}
          help={formDeviceHelpDrawerTitleState.isError ? formDeviceHelpDrawerTitleState.errorMessage : undefined}
        >
          <InputAtom
            showCount
            maxLength={formDeviceHelpDrawerTitleState.maxLength}
            placeholder={formDeviceHelpDrawerTitleState.placeholder}
            value={formDeviceHelpDrawerTitleState.defaultValue}
            disabled={formDeviceHelpDrawerTitleState.isDisabled}
            onChange={(event) => {
              formDeviceHelpDrawerTitleState.onChange(event.target.value);
            }}
          />
        </FormAtom.Item>
        <FormAtom.Item
          label={formDeviceHelpDrawerDescriptionState.label}
          validateStatus={formDeviceHelpDrawerDescriptionState.isError ? 'error' : undefined}
          help={formDeviceHelpDrawerDescriptionState.isError ? formDeviceHelpDrawerDescriptionState.errorMessage : undefined}
        >
          <TextArea
            showCount
            maxLength={formDeviceHelpDrawerDescriptionState.maxLength}
            placeholder={formDeviceHelpDrawerDescriptionState.placeholder}
            value={formDeviceHelpDrawerDescriptionState.defaultValue}
            disabled={formDeviceHelpDrawerDescriptionState.isDisabled}
            onChange={(event) => {
              formDeviceHelpDrawerDescriptionState.onChange(event.target.value);
            }}
          />
        </FormAtom.Item>
      </FormAtom>
      <WebappPreview
        title={formDeviceHelpDrawerTitleState.defaultValue}
        description={formDeviceHelpDrawerDescriptionState.defaultValue}
        isActiveInfo={isVisiblePreviewInfo}
      />
    </main>
  )
}

export default DeviceHelpTemplate;