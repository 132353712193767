import { FC } from 'react';
import { DeviceHelpTextInputProps } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/useDeviceHelpDrawer/models';
import SwitchAtom from 'components/atoms/SwitchAtom';
import SkeletonAtom from 'components/atoms/SkeletonAtom';
import FormAtom from 'components/atoms/FormAtom';
import WebappPreview from '../WebappPreview';
import './styles.scss';

interface Props {
  title: string;
  formDeviceHelpDrawerDescriptionState: DeviceHelpTextInputProps;
  formDeviceHelpDrawerTitleState: DeviceHelpTextInputProps;
}

const DeviceHelpLoadingTemplate: FC<Props> = ({
  title,
  formDeviceHelpDrawerTitleState,
  formDeviceHelpDrawerDescriptionState,
}) => {
  return (
    <main>
      <div
        className={'DeviceHelpLoadingTemplate__active-toggle'}
      >
        <p>{title}</p>
        <SwitchAtom
          checked={false}
          loading
        />
      </div>
      <FormAtom layout={'vertical'}>
        <FormAtom.Item
          label={formDeviceHelpDrawerTitleState.label}
        >
          <SkeletonAtom.Input
            active
            className={'DeviceHelpLoadingTemplate__skeleton-input'}
          />
        </FormAtom.Item>
        <FormAtom.Item
          label={formDeviceHelpDrawerDescriptionState.label}
        >
          <SkeletonAtom.Input
            active
            className={'DeviceHelpLoadingTemplate__skeleton-text-area'}
          />
        </FormAtom.Item>
      </FormAtom>
      <WebappPreview isActiveInfo={false} />
    </main>
  )
}

export default DeviceHelpLoadingTemplate;