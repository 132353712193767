import api from 'services/api';
import { Moment } from 'moment';
import {
  AccessControlResponse,
  AssetDashboard,
  AssetsResponse,
  RegisterAsset,
  Invitation,
  CreateAssetAnonymousAccess,
  AssetDevices,
  AssetAnonymousAccess,
  AssetResponse,
  UpdateAssetInformation,
} from 'models/assets.model';
import { ListResponsePagination, Response } from 'models/general.model';
import { SupportTicketStatus } from 'models/supportTickets.model';
import { DeviceWithGatewayId, EnableDevice } from 'models/devices.model';

interface GetAssetDashboardOptions {
  page?: { number?: number; size?: number };
  status?: SupportTicketStatus;
}

interface GetAssetsOptions {
  current?: number;
  size?: number;
  search?: string;
}

export const getAsset = async (assetId: string, include?: 'users'): Promise<AssetResponse> => {
  const params = new URLSearchParams();
  include && params.append('include', include);
  return await api.get(`/assets/${assetId}`, { params });
};

export const getAssetDashboard = async (assetId: string, options?: GetAssetDashboardOptions): Promise<AssetDashboard | undefined> => {
  const params = new URLSearchParams();
  try {
    if (options?.page?.number) params.append('page[number]', `${options.page.number}`);
    if (options?.page?.size) params.append('page[size]', `${options.page.size}`);
    if (options?.status) params.append('status', `${options.status}`);
    api.defaults.headers.common['Accept-version'] = '1';
    const response = await api.get<AssetDashboard>(`/assets/${assetId}/dashboard`, { params });
    return response?.data;
  } catch (error) {
    console.warn(error);
  }
};

export const getAssets = (options?: GetAssetsOptions): Promise<AssetsResponse> => {
  const params = new URLSearchParams();
  if (options?.current) params.append('page[number]', `${options?.current}`);
  if (options?.size) params.append('page[size]', `${options?.size}`);
  if (options?.search) params.append('search', `${options?.search}`);
  return api.get('/assets', { params });
};

export const registerAsset = async (data: RegisterAsset): Promise<void> => {
  try {
    return await api.post('/assets', { data });
  } catch (error) {
    console.warn(error);
  }
};

export const createAnonymousInvitation = async (data: CreateAssetAnonymousAccess): Promise<Invitation | undefined> => {
  const { data: invitation }: { data: Invitation | undefined } = await api.post('/assets/anonymous-access', {
    data,
  });
  return invitation;
};

export const getAnonymousAccessList = async (): Promise<Response<AssetAnonymousAccess>> => await api.get('/assets/anonymous-access/');

interface getAssetAnonymousAccessListOptions {
  pageNumber?: number;
  pageSize?: number;
}

export const getAssetAnonymousAccessList = async (
  assetId: string,
  options: getAssetAnonymousAccessListOptions
): Promise<ListResponsePagination<AssetAnonymousAccess[]> | undefined> => {
  const params = new URLSearchParams();
  if (options?.pageNumber) params.append('page[number]', `${options?.pageNumber}`);
  if (options?.pageSize) params.append('page[size]', `${options?.pageSize}`);
  return await api.get(`/assets/${assetId}/anonymous-access`, { params });
};

export const getAssetDevices = async (assetId: string, type?: 'lock'): Promise<AssetDevices | undefined> => {
  const params = new URLSearchParams();
  type && params.append('type', `${type}`);
  const response = await api.get<AssetDevices>(`/assets/${assetId}/devices`, { params });
  return response ? response.data : undefined;
};

export const getAccessControl = async (assetId: string, from?: Moment, until?: Moment): Promise<AccessControlResponse> => {
  const params = new URLSearchParams();
  if (from) params.append('from', `${from.unix()}`);
  if (until) params.append('until', `${until.unix()}`);
  api.defaults.headers.common['Accept-version'] = '1';
  return await api.get(`/data/assets/time-series/${assetId}/access-control`, { params });
};

export const enableAccess = (assetId: string, data: EnableDevice): Promise<void> => {
  return api.post(`/assets/${assetId}/device/toggle`, {
    data,
  });
};

export const getAccessPublicDevices = async (assetId: string): Promise<DeviceWithGatewayId[]> => {
  const response = await getAssetDevices(assetId, 'lock');

  return response
    ? response.gateways.flatMap(({ devices, uuid }) => {
        return devices.map<DeviceWithGatewayId>((device) => ({ gatewayId: uuid, ...device }));
      })
    : [];
};

export const getAccessManagementPasswordDevices = async (assetId: string): Promise<DeviceWithGatewayId[]> => {
  const response = await getAssetDevices(assetId);
  return response
    ? response.gateways.flatMap(({ uuid, devices }) => {
        return devices
          .filter((device) => device.available_actions.some((action) => action === 'management_password'))
          .map<DeviceWithGatewayId>((device) => ({ gatewayId: uuid, ...device }));
      })
    : [];
};

export const updateAssetInformation = async (assetId: string, options: UpdateAssetInformation): Promise<void> => {
  const data = { id: assetId, attributes: options };
  await api.patch(`/assets/${assetId}`, { data });
};
