import { FC } from 'react';
import { Image } from 'antd';
import { Sku } from 'models/gateways.model';
import { IMAGES } from 'constants/images';
import { GATEWAY_IMAGES } from './config';
import './styles.scss';

interface GatewayImageProps {
  className: string;
  sku: Sku | null | undefined;
}

const getImage = (sku: Sku | null | undefined): string => {
  if (!sku) return IMAGES.noImage;

  const acceptedSkuValues = Object.values(GATEWAY_IMAGES);
  const isSkuAcceptedValue = acceptedSkuValues.some((value) => value.sku === sku);

  return isSkuAcceptedValue ? GATEWAY_IMAGES[sku].image : IMAGES.noImage;
}

const GatewayImage: FC<GatewayImageProps> = ({ className, sku }) => {
  return (
    <Image className={`${className} GatewayImage`} key={sku ?? 'no_image'} src={getImage(sku)} />
  );
};

export default GatewayImage;
