import { DeviceModel, DevicePayload, DeviceSensorModel, DeviceSensorPayload } from '../models';

const payloadToDeviceSensor = ({
  access_sensor_id,
  device_id,
  name,
  room,
  sensor_id,
  sensor_type,
  sensor_uuid,
  usage,
}: DeviceSensorPayload): DeviceSensorModel => {
  return {
    accessSensorId: access_sensor_id ?? '',
    deviceId: device_id ?? '',
    name: name ?? '',
    room: room ?? '',
    sensorId: sensor_id ?? '',
    sensorType: sensor_type ?? '',
    sensorUuid: sensor_uuid ?? '',
    usage: usage ?? null,
  };
};

const payloadToDevice = ({
  asset_id,
  available_actions,
  battery_level,
  connectivity,
  device_id,
  device_name,
  device_type,
  enabled,
  gateway_uuid,
  parameters,
  project_id,
  sensors,
  shared,
  uuid,
}: DevicePayload): DeviceModel => {
  const transformedSensors = sensors.map((sensor) => payloadToDeviceSensor(sensor)) ?? [];

  return {
    assetId: asset_id ?? null,
    availableActions: available_actions ?? [],
    batteryLevel: battery_level ?? null,
    connectivity: connectivity ?? null,
    deviceId: device_id ?? '',
    deviceName: device_name ?? '',
    deviceType: device_type ?? '',
    enabled: enabled ?? false,
    gatewayUuid: gateway_uuid ?? null,
    parameters: parameters ?? [],
    projectId: project_id ?? null,
    sensors: transformedSensors,
    shared: shared ?? false,
    uuid: uuid ?? '',
  };
};

export const payloadToDevicesList = (devices: DevicePayload[]): DeviceModel[] => {
  return devices.map((device) => payloadToDevice(device));
};
