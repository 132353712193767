import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import {
  DeviceHelpDataModel,
  DeviceHelpDataPayloadResponse,
  GetDeviceHelpParams,
} from '../models';
import { deviceHelpDataPayloadToModel } from '../mappers';
import { getErrorFormat } from 'core/utils/errors';

const getUrl = ({ host, deviceId, gatewayId }: {
  host: string,
  deviceId: string,
  gatewayId: string,
}) => {
  return `${host}/devices/help/${deviceId}/gateways/${gatewayId}`;
};

export const GetDeviceHelpData = async ({
  deviceId,
  gatewayId,
  host,
  token
}: GetDeviceHelpParams): Promise<DeviceHelpDataModel> => {
  try {
    const url = getUrl({ host, deviceId, gatewayId });

    const options: AxiosRequestConfig = {
      url,
      method: 'GET',
      timeout: 10000,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: token,
        'Accept-version': '1',
      },
    };

    const { data } = await axios(options) as AxiosResponse<DeviceHelpDataPayloadResponse>;
    return deviceHelpDataPayloadToModel(data);
  } catch (error) {
    throw getErrorFormat(error);
  }
}