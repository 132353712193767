import i18n from 'config/i18n';
import { DeviceHelpTextInputProps } from './models';

export const getInitialDeviceHelpTitleData = (): DeviceHelpTextInputProps => ({
  label: i18n.t('_ACCESS_DETAIL_HELP_DRAWER_FORM_LABEL_TITLE'),
  defaultValue: i18n.t('_ACCESS_DETAIL_HELP_DRAWER_FORM_VALUE_TITLE'),
  maxLength: 44,
  placeholder: i18n.t('_ACCESS_DETAIL_HELP_DRAWER_FORM_PLACEHOLDER_TITLE'),
  errorMessage: i18n.t('_ACCESS_DETAIL_HELP_DRAWER_FORM_ERROR_TITLE'),
  onChange: () => null,
  isDisabled: true,
  isError: false,
});

export const getInitialDeviceHelpDescriptionData = (): DeviceHelpTextInputProps => ({
  label: i18n.t('_ACCESS_DETAIL_HELP_DRAWER_FORM_LABEL_DESCRIPTION'),
  defaultValue: i18n.t('_ACCESS_DETAIL_HELP_DRAWER_FORM_VALUE_DESCRIPTION'),
  maxLength: 150,
  placeholder: i18n.t('_ACCESS_DETAIL_HELP_DRAWER_FORM_PLACEHOLDER_DESCRIPTION'),
  errorMessage: i18n.t('_ACCESS_DETAIL_HELP_DRAWER_FORM_ERROR_DESCRIPTION'),
  onChange: () => null,
  isDisabled: true,
  isError: false,
});

export const resetDeviceHelpTitleData = getInitialDeviceHelpTitleData;
export const resetDeviceHelpDescriptionData = getInitialDeviceHelpDescriptionData;
