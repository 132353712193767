import { FC } from 'react';
import DrawerAtom from 'components/atoms/DrawerAtom';
import LayoutAtom from 'components/atoms/LayoutAtom';
import { MenuOutlined } from '@ant-design/icons';
import SpaceAtom from 'components/atoms/SpaceAtom';
import SuperSearchManager from 'components/organisms/SuperSearchManager';
import AvatarMenu from 'components/molecules/AvatarMenu';
import SideMenu from 'components/molecules/SideMenu';
import { MenuItemProps } from 'components/atoms/MenuItem';
import { HeaderCustomization } from 'components/pages/AdminPage/resources/useAdmin';
import './styles.scss';
import { Link } from 'react-router-dom';
import WaterMark from 'components/molecules/WaterMark';

const SIZE_WIDTH_DRAWER = '300'

interface Props {
  headerCustomization: HeaderCustomization;
  sideMenuItems: MenuItemProps[],
  activeMenuItem: string,
  onClickMenuItem: (path: string) => void,
  onReachLayoutBreakpoint: (broken: boolean) => void,
  onCloseMenu: () => void,
  onClickMenuIcon: () => void,
  visibleMenuIcon: boolean;
  visibleDrawer: boolean;
  logoRoute: string;
  children?: React.ReactNode;
}

const AdminTemplate: FC<Props> = ({
  headerCustomization,
  sideMenuItems,
  activeMenuItem,
  children,
  onClickMenuItem,
  onReachLayoutBreakpoint,
  onCloseMenu,
  onClickMenuIcon,
  visibleMenuIcon,
  visibleDrawer,
  logoRoute,
}) => {
  const { logo, backgroundColor, searchIconColor, avatarColor } = headerCustomization;

  return (
    <LayoutAtom className={'AdminTemplate'}>
      <LayoutAtom.Header className={'AdminTemplate__header'} style={{ backgroundColor }}>
        <Link to={logoRoute}>
          <div className={'AdminTemplate__logo'}>
            <img src={logo} alt={'logo'} />
          </div>
        </Link>
        <div>
          <SpaceAtom size={'middle'}>
            <div className={'AdminTemplate__topBar'}>
              <SuperSearchManager iconColor={searchIconColor} />
              <AvatarMenu color={avatarColor} />
            </div>
            {visibleMenuIcon && (
              <MenuOutlined onClick={onClickMenuIcon} style={{ color: searchIconColor }} />
            )}
          </SpaceAtom>
        </div>
      </LayoutAtom.Header>
      <LayoutAtom>
        <LayoutAtom.Sider
          className={'AdminTemplate__sider'}
          breakpoint={'lg'}
          collapsedWidth={0}
          zeroWidthTriggerStyle={{ display: 'none' }}
          onBreakpoint={onReachLayoutBreakpoint}
        >
          <SideMenu
            items={sideMenuItems}
            activeItem={activeMenuItem}
            onClick={onClickMenuItem}
          />
          <div className={'AdminTemplate__waterMark-wrapper'}>
            <WaterMark logoColor={'#BBBBBB'} logoWidth={'128'} />
          </div>
        </LayoutAtom.Sider>
        <DrawerAtom
          width={SIZE_WIDTH_DRAWER}
          placement={'right'}
          onClose={onCloseMenu}
          open={visibleDrawer}
        >
          <div className={'AdminTemplate__drawer-wrapper'}>
            <SideMenu
              items={sideMenuItems}
              activeItem={activeMenuItem}
              onClick={onClickMenuItem}
            />
            <div className={'AdminTemplate__drawer-wrapper-waterMark'}>
              <WaterMark logoColor={'#BBBBBB'} logoWidth={'128'} />
            </div>
          </div>
        </DrawerAtom>
        <LayoutAtom.Content className={'AdminTemplate__content'}>
          <LayoutAtom>{children}</LayoutAtom>
        </LayoutAtom.Content>
      </LayoutAtom>
    </LayoutAtom>
  );
};

export default AdminTemplate;