import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InputRef, TableProps } from 'antd';
import { SorterResult, SortOrder } from 'antd/lib/table/interface';
import { useUserSession } from 'hooks/useUserSession';
import { useEnvironment } from 'hooks/useEnvironment';
import { AccessDeviceModel, AccessDeviceSortModel, AccessDeviceTypeModel } from 'core/domain/access/models';
import { GetAccessDevicesListByProjectId } from 'core/domain/access/repository/getAccessDevicesListByProjectId';
import { useMessage } from 'hooks/useMessage';
import {
  HotelAccessDevicesListColumns,
  HotelAccessDevicesListColumnsLiteralsModel,
  HotelAccessDevicesListColumnsOrderType,
} from './AccessDevicesListColumns';
import { OpenHotelAccessDeviceModalLiteralsModel } from './OpenHotelAccessDeviceModal';
import { TABLE_PAGINATION_PAGE_DEFAULT, TABLE_PAGINATION_SIZE_DEFAULT, TablePaginationSearchValue } from 'constants/table';
import { getHotelAccessDevicesListWithPaginationPath } from 'components/pages/App/routes/access/config';
import { OpenAccessDevice } from 'core/domain/access/repository/openAccessDevice';

const DEFAULT_TOTAL_PAGES_VALUE = 1;

export const useHotelAccessDevicesList = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { clientId, projectId } = useParams<{ clientId: string; projectId: string }>();
  const page = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.PAGE) || TABLE_PAGINATION_PAGE_DEFAULT;
  const size = new URLSearchParams(window.location.search).get(TablePaginationSearchValue.SIZE) || TABLE_PAGINATION_SIZE_DEFAULT;
  const { host } = useEnvironment();
  const { token } = useUserSession();
  const { setMessageError } = useMessage();
  const [accessDevicesList, setAccessDevicesList] = useState<AccessDeviceModel[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAccessModalVisible, setOpenAccessModalVisible] = useState<boolean>(false);
  const [currentAccessDevice, setCurrentAccessDevice] = useState<AccessDeviceModel | null>(null);
  const [totalPages, setTotalPages] = useState<number>(DEFAULT_TOTAL_PAGES_VALUE);
  const [pageCurrent, setPageCurrent] = useState(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  const [pageSize, setPageSize] = useState(Number(TABLE_PAGINATION_SIZE_DEFAULT));
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortOptions, setSortOptions] = useState<AccessDeviceSortModel[]>([]);

  const inputRef = useRef<InputRef | null>(null);

  const searchButtonText: string = t('search_with_ellipsis');

  const columnsLiterals: HotelAccessDevicesListColumnsLiteralsModel = {
    nameTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_NAME_TITLE'),
    roomTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_ROOM_TITLE'),
    areaTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_AREA_TITLE'),
    batteryTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_BATTERY_LEVEL_TITLE'),
    deviceTypeTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_DEVICE_TYPE_TITLE'),
    actionsTitle: t('_ACCESS_DEVICES_LIST_COLUMNS_ACTIONS_TITLE'),
    openAccessDeviceText: t('_ACCESS_DEVICES_LIST_OPEN_ACCESS_TEXT'),
  };

  const openAccessDeviceModalLiterals: OpenHotelAccessDeviceModalLiteralsModel = {
    title: t('_OPEN_ACCESS_DEVICE_MODAL_TITLE', { name: currentAccessDevice?.name || '' }),
    text: t('_OPEN_ACCESS_DEVICE_MODAL_TEXT'),
    description: t('_OPEN_ACCESS_DEVICE_MODAL_DESCRIPTION'),
    primaryButtonText: t('_ACCESS_DEVICES_OPEN_MAIN_BUTTON_MODAL'),
    secondaryButtonText: t('cancel'),
  };

  const getAccessDevicesList = async ({
    page,
    size,
    search,
    sort,
  }: {
    page: number;
    size: number;
    search?: string;
    sort: AccessDeviceSortModel[];
  }) => {
    try {
      const { data, meta } = await GetAccessDevicesListByProjectId({
        host, token, projectId, page, size, search, sort
      });
      setAccessDevicesList(data);
      setTotalPages(meta.totalPages);
    } catch (error) {
      setMessageError({ description: t('_ACCESS_DEVICES_LIST_ERROR_MESSAGE') });
    } finally {
      setLoading(false);
    }
  };

  const onOpenAccessDeviceModal = (selectedAccessDevice: AccessDeviceModel) => {
    setOpenAccessModalVisible(true);
    setCurrentAccessDevice(selectedAccessDevice);
  };

  const onCloseAccessDeviceModal = () => {
    setOpenAccessModalVisible(false);
    setCurrentAccessDevice(null);
  };

  const onOpenAccessDevice = async () => {
    try {
      if (!!currentAccessDevice) {
        const { accessSensorId } = currentAccessDevice;
        await OpenAccessDevice({ host, token, accessSensorId });
        onCloseAccessDeviceModal();
      }
    } catch (error) {
      setMessageError({ description: t('_ACCESS_DEVICES_LIST_OPEN_ACCESS_DEVICE_ERROR_MESSAGE') });
    }
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    setSearchQuery(value);
    setPageCurrent(Number(TABLE_PAGINATION_PAGE_DEFAULT));
  };

  const onUpdateSearchValue = (newValue: string) => {
    setSearchValue(newValue);
  };

  const onChangePage = (page: number, pageSize?: number) => {
    setPageCurrent(page);
    setPageSize(pageSize!);
    history.push(getHotelAccessDevicesListWithPaginationPath({ clientId, projectId, page: String(page), size: String(pageSize!) }));
  };

  const orderManager = {
    [HotelAccessDevicesListColumnsOrderType.ASCEND]: 'asc',
    [HotelAccessDevicesListColumnsOrderType.DESCEND]: 'desc',
  };

  const transformOrder = (order?: SortOrder): string => {
    return !!order ? orderManager[order as HotelAccessDevicesListColumnsOrderType] : '';
  };

  const updateSortOptions = (key: string, currentSortOptions: AccessDeviceSortModel[], newSortOptions: AccessDeviceSortModel[]) => {
    return currentSortOptions.map((option) => {
      return option.key === key ? { key: newSortOptions[0].key, order: newSortOptions[0].order } : option;
    });
  };

  const checkSortOptions = (key: string, newSortOptions: AccessDeviceSortModel[]) => {
    const currentSortOptions = [...sortOptions];
    const sortOptionAlreadyExists = currentSortOptions.some((option) => option.key.includes(key));
    return sortOptionAlreadyExists ? updateSortOptions(key, currentSortOptions, newSortOptions) : [...sortOptions, ...newSortOptions];
  };

  const reduceSortOptions = (key: string) => {
    const currentSortOptions = [...sortOptions];
    return currentSortOptions.filter((option) => option.key !== key);
  };

  const editSortOptions = ({ key, newSortOptions }: { key: string; newSortOptions: AccessDeviceSortModel[] }) => {
    const filteredSortOptions = !!newSortOptions.length ? checkSortOptions(key, newSortOptions) : reduceSortOptions(key);
    setSortOptions(filteredSortOptions);
  };

  const checkSorterObject = (sorter: SorterResult<AccessDeviceModel>) => {
    const { columnKey, order } = sorter;
    const transformedOrder = transformOrder(order);
    const sortOptions: AccessDeviceSortModel[] =
      !!columnKey && !!transformedOrder ? [{ key: columnKey as string, order: transformedOrder }] : [];
    !!columnKey && editSortOptions({ key: columnKey as string, newSortOptions: sortOptions });
  };

  const onChangeAccessDevicesTable: TableProps<AccessDeviceModel>['onChange'] = (pagination, filters, sorter) => {
    !Array.isArray(sorter) && checkSorterObject(sorter);
  };

  const getDeviceTypeTranslation = ({ vendor, model }: AccessDeviceTypeModel): string => {
    return t('_ACCESS_DEVICES_LIST_DEVICE_TYPE_TEXT', { vendor, model });
  };

  useEffect(() => {
    setPageCurrent(Number(page));
    setPageSize(Number(size));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size]);

  useEffect(() => {
    setLoading(true);
    getAccessDevicesList({ page: Number(page), size: Number(size), search: searchQuery, sort: sortOptions });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, size, searchQuery, sortOptions]);

  const columns = HotelAccessDevicesListColumns({
    literals: columnsLiterals,
    onOpenAccessDevice: onOpenAccessDeviceModal,
    getDeviceTypeTranslation,
  });

  const openAccessDeviceModalOptions = {
    openAccessDeviceModalLiterals,
    onCloseAccessDeviceModal,
    onOpenAccessDevice,
    openAccessModalVisible,
  };

  return {
    accessDevicesList,
    pageCurrent,
    pageSize,
    totalPages,
    columns,
    searchValue,
    searchValueText: searchButtonText,
    inputRef,
    openAccessDeviceModalOptions,
    onChangePage,
    onSearch,
    onUpdateSearchValue,
    onChangeAccessDevicesTable,
    loadingAccessDevices: loading,
  };
};
