
export interface DeviceHelpTextInputProps {
  label: string;
  defaultValue: string;
  placeholder: string;
  errorMessage: string;
  maxLength: number;
  onChange: (value: string) => void;
  isDisabled: boolean;
  isError: boolean;
}

export interface Action {
  key: DrawerItem;
  payload: DeviceHelpTextInputProps;
}

export enum DrawerItem {
  DEVICE_HELP_FORM_TITLE = 'deviceHelpFormTitleState',
  DEVICE_HELP_FORM_DESCRIPTION = 'deviceHelpFormDescriptionState',
}

export interface DeviceHelpDrawerInitialStatesModel {
  [DrawerItem.DEVICE_HELP_FORM_TITLE]: DeviceHelpTextInputProps,
  [DrawerItem.DEVICE_HELP_FORM_DESCRIPTION]: DeviceHelpTextInputProps,
}
