import { SupportTicketsStatisticsModel } from 'core/domain/supportTickets/models/supportTicketsModel';
import { UsersListUnstructuredStatisticsModel } from 'core/domain/users/models/usersModel';

export enum Dashboard {
  OCCUPATION = 'occupation',
  ACTIVE_USERS = 'activeUsers',
  SUPPORT_TICKETS = 'supportTickets',
  ACTIVE_GATEWAYS = 'activeGateways',
}

export interface OccupationLiteralsTypes {
  title: string;
  titleTimePeriod: string;
  occupied: string;
  unoccupied: string;
  errorTitle: string;
  errorDescription: string;
}

export interface OccupationDashboardProps {
  assetsLinked: number;
  assetsNotLinked: number;
  literals: OccupationLiteralsTypes;
  isLoading: boolean;
  isError: boolean;
}

export interface UsersLiteralsTypes {
  title: string;
  titleTimePeriod: string;
  usersAverage: string;
  errorTitle: string;
  errorDescription: string;
}

export interface ActiveUsersDashboardProps {
  statistics: UsersListUnstructuredStatisticsModel;
  literals: UsersLiteralsTypes;
  isLoading: boolean;
  isError: boolean;
}

export interface SupportTicketsLiteralsTypes {
  title: string;
  titleTimePeriod: string;
  openTickets: string;
  ticketsClosed: string;
  ticketsClosedTimeAverage: string;
  firstResponseTimeAverage: string;
  ticketsReceived: string;
  ticketsRevoked: string;
  ticketsInProgress: string;
}

export interface SupportTicketsDashboardProps {
  statistics: SupportTicketsStatisticsModel;
  openTickets: number;
  literals: SupportTicketsLiteralsTypes;
  onClickOpened: () => void;
  isLoading: boolean;
  isError: boolean;
}

export enum SupportTicketsStatisticsUnit {
  MINUTES = 'minutes',
  MINUTE = 'minute',
  SECONDS = 'seconds',
  HOURS = 'hours',
  HOUR = 'hour',
  DAY = 'day',
  DAYS = 'days',
  DEFAULT = '',
}

export interface CustomActiveGatewaysStatisticsModel {
  totalGatewaysOnline: number;
  labels: string[];
  values: number[];
}

interface ActiveGatewayLiteralsTypes {
  title: string;
  subtitle: string;
  text: string;
  errorTitle: string;
  errorDescription: string;
  chartSubtitle: string;
}

export interface ActiveGatewaysDashboardProps {
  statistics: CustomActiveGatewaysStatisticsModel;
  literals: ActiveGatewayLiteralsTypes;
  isLoading: boolean;
  isError: boolean;
}

export interface DashboardInitialStatesModel {
  [Dashboard.OCCUPATION]: OccupationDashboardProps,
  [Dashboard.ACTIVE_USERS]: ActiveUsersDashboardProps,
  [Dashboard.SUPPORT_TICKETS]: SupportTicketsDashboardProps,
  [Dashboard.ACTIVE_GATEWAYS]: ActiveGatewaysDashboardProps,
}
