import { FC } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  Divider,
  Typography,
  Badge,
  Popover,
  Tooltip,
  Image
} from 'antd';
import { CustomGatewayDataModel, GatewayStatusInformationTranslations, INITIAL_GATEWAY_STATUS } from '../../resources/utils';
import { DATE_FORMAT, DATE_TIME_FORMAT } from 'constants/date';
import { COLORS } from 'constants/colors';
import GatewayImage from 'components/organisms/GatewayImage';
import HealthProgress from 'components/molecules/HealthProgress';
import { IMAGES } from 'constants/images';
import SpaceAtom from 'components/atoms/SpaceAtom';
import ContextualMenuLinks from './ContextualMenuLinks';
import LinkButton from 'components/atoms/Button/LinkButton';
import TagSuccess from 'components/atoms/tags/TagSuccess';
import TagError from 'components/atoms/tags/TagError';
import GatewayStatusNetworkStateManager from 'components/molecules/GatewayStatusNetworkStateManager';
import { checkIfVersionIsOutdated } from 'utils/checkVersions';
import { ActiveIssue } from 'core/domain/gateways/model/activeIssue';
import { GatewayType } from 'core/domain/gateways/model/gateway/gatewayUnparsed';
import './styles.scss';

const VERSION_MIN_OPENHAB = '2.11.0';

interface StatusGatewayBlockProps {
  gateway: CustomGatewayDataModel;
  translations: GatewayStatusInformationTranslations;
  onUnassignGateway: () => void;
  onUpdateGateway: (gateway: CustomGatewayDataModel) => void;
  hasPermissionToEditGatewayLink: boolean;
  isGatewayLinked: boolean;
}

const GatewayInformationBlock: FC<StatusGatewayBlockProps> = ({
  gateway,
  translations,
  onUnassignGateway,
  onUpdateGateway,
  hasPermissionToEditGatewayLink,
  isGatewayLinked,
}) => {
  const {
    systemConfig,
    id,
    gatewayVersion,
    managementLinks,
    sku,
    productionDate,
    hwVersion,
    qrCode,
    daemonVersion,
    status,
    type,
  } = gateway;
  const activeIssues = status?.activeIssues || INITIAL_GATEWAY_STATUS.activeIssues;
  const isDisabledContextualMenuLinks = checkIfVersionIsOutdated({ minimumVersion: VERSION_MIN_OPENHAB, version: gatewayVersion });
  const isConnectionStatusReported = !!activeIssues;
  const isOffline = !!activeIssues && activeIssues.includes(ActiveIssue.ONLINE);
  const percentPackageLost = status?.networkState?.percentagePackageLost;
  const latency = status?.networkState?.latency;
  const bandWidthValue = status?.networkState?.bandWidth;

  const {
    title,
    notReportedText,
    productionDateText,
    networkStateText,
    networkStateStatusTranslations,
    networkStateLatencyText,
    networkStateBandWidthText,
    networkStateLostPackagesText,
    networkInterfaceText,
    networkInterfaceDetailTranslations,
    lastConnectionText,
    statusText,
    statusOfflineText,
    statusOnlineText,
    healthText,
    activeIssuesText,
    activeIssuesDetailTranslations,
    gatewayVersionText,
    gatewayVersionUpdateButtonText,
    updateModuleErrorMessage,
    updateButtonInProgressText,
  } = translations;

  return (
    <>
      <div className={'GatewayInformation__title'}>
        <Typography.Title level={4}>{title}:</Typography.Title>
        <div className={'GatewayInformation__title-contextualMenu'}>
          <ContextualMenuLinks
            managementLinks={managementLinks}
            disabledContextualMenuLinks={isDisabledContextualMenuLinks}
            isGatewayLinked={isGatewayLinked}
            onUnassignGateway={onUnassignGateway}
            hasPermissionToEditGatewayLink={hasPermissionToEditGatewayLink}
            isSharedGateway={type === GatewayType.SHARED}
          />
        </div>
      </div>
      <div className={'GatewayInformation'}>
        {isConnectionStatusReported && (
          <Badge status={isOffline ? 'error' : 'success'} count={!!activeIssues && activeIssues.length}>
            <SpaceAtom direction={'vertical'}>
              <GatewayImage className={'GatewayInformation__image'} sku={sku} />
            </SpaceAtom>
          </Badge>
        )}
        {!isConnectionStatusReported && (
          <SpaceAtom direction={'vertical'}>
            <GatewayImage className={'GatewayInformation__image'} sku={sku} />
          </SpaceAtom>
        )}
        <Divider className={'GatewayInformation__divider'} type={'vertical'} />
        <div className={'GatewayInformation__box  GatewayInformation__box-one'}>
          <p>
            <b>{productionDateText}: </b>
            {productionDate ? moment.unix(productionDate).format(DATE_FORMAT) : notReportedText}
          </p>
          <p>
            <b>Hardware: </b>
            {hwVersion ? hwVersion : notReportedText}
          </p>
          <p>
            <b>SKU: </b>
            {sku ? sku : notReportedText}
          </p>
          <p>
            <Tooltip className={'GatewayInformation__qid'} title={<Image src={String(IMAGES.qid)} />}>
              <InfoCircleOutlined />
            </Tooltip>
            <b> QID:</b>
            {qrCode ? ` ${qrCode} ` : ` ${notReportedText}`}
          </p>
        </div>
        <Divider className={'GatewayInformation__divider'} type={'vertical'} />
        <div className={'GatewayInformation__box'}>
          <p>
            <b>GID: </b>
            {id}
          </p>
          <p>
            <b>VPN IP: </b>
            {status?.vpnIp ? status.vpnIp : notReportedText}
          </p>
          <p>
            <Popover
              content={
                <>
                  <p className={'GatewayInformation__text-popover'}>
                    <b>{networkStateLostPackagesText}: </b>
                    <i>{percentPackageLost || percentPackageLost === 0 ? `${percentPackageLost}%` : notReportedText}</i>
                  </p>
                  <p className={'GatewayInformation__text-popover'}>
                    <b>{networkStateLatencyText}: </b>
                    <i>{latency || latency === 0 ? latency : notReportedText}</i>
                  </p>
                  <p className={'GatewayInformation__text-popover'}>
                    <b>{networkStateBandWidthText}: </b>
                    <i>{bandWidthValue || bandWidthValue === 0 ? bandWidthValue : notReportedText}</i>
                  </p>
                </>
              }
            >
              <InfoCircleOutlined />
              <b> {networkStateText}: </b>
              {!!status?.networkState?.status && <GatewayStatusNetworkStateManager status={status.networkState.status} translations={networkStateStatusTranslations} />}
              {!status?.networkState?.status && notReportedText}
            </Popover>
          </p>
          <p>
            <b>{networkInterfaceText}: </b>
            {!!status?.networkInterface ? networkInterfaceDetailTranslations[status.networkInterface] : notReportedText}
          </p>
        </div>
        <Divider className={'GatewayInformation__divider'} type={'vertical'} />
        <div className={'GatewayInformation__maxi-box'}>
          <div className={'GatewayInformation__box'}>
            <p className={'GatewayInformation__text-updatable'}>
              <b>AASS: </b>
              {daemonVersion ? daemonVersion : notReportedText}<br />
            </p>
            <p className={'GatewayInformation__text-updatable-secondary'}>
              <b>{gatewayVersionText}: </b>
              {gatewayVersion ? gatewayVersion : notReportedText}
            </p>
            {systemConfig.isVersionUpdateError && (
              <p className={'GatewayInformation__text-updatable-error'}>
                {updateModuleErrorMessage}
              </p>
            )}
            {systemConfig.isUpdateButtonVisible && (
              <div className={'GatewayInformation__button'}>
                <LinkButton
                  title={systemConfig.isVersionUpdating ? updateButtonInProgressText : gatewayVersionUpdateButtonText}
                  loading={systemConfig.isVersionUpdating}
                  disabled={systemConfig.isUpdateButtonDisabled}
                  onClick={() => onUpdateGateway(gateway)}
                />
              </div>
            )}
          </div>
        </div>
        <Divider className={'GatewayInformation__divider'} type={'vertical'} />
        <div className={'GatewayInformation__box'}>
          <p>
            <b>{lastConnectionText}: </b>
            {status?.lastConnection ? `${moment.unix(status.lastConnection).format(DATE_TIME_FORMAT)}` : notReportedText}
          </p>
          <p>
            <b>{statusText}: </b>
            {!isConnectionStatusReported && notReportedText}
            {isConnectionStatusReported && isOffline && <TagError text={statusOfflineText} />}
            {isConnectionStatusReported && !isOffline && <TagSuccess text={statusOnlineText} />}
          </p>
          <p>
            <b>{healthText}: </b>
            {(!!status?.health || status?.health === 0)
              ? <span className={'GatewayInformation__box-inline-info'}>
                <HealthProgress percent={status.health} />
              </span>
              : notReportedText}
          </p>
          <p>
            <b>{activeIssuesText}: </b>
            {!!activeIssues && activeIssues.length && (
              <Popover
                title={activeIssuesText}
                content={
                  <>
                    {activeIssues.map((issue, key) => (
                      <p key={key}>
                        <i>{activeIssuesDetailTranslations[issue]}</i>
                      </p>
                    ))}
                  </>
                }
              >
                <Badge
                  className={'GatewayInformation__popover'}
                  style={{ backgroundColor: activeIssues ? COLORS.orange : COLORS.success }}
                  count={activeIssues.length}
                />
              </Popover>
            )}
            {!activeIssues && notReportedText}
          </p>
        </div>
      </div>
    </>
  );
};

export default GatewayInformationBlock;
