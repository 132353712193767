import { FC } from 'react';
import { COLORS } from 'constants/colors';
import DeviceHelpLoadingTemplate from 'components/template/DeviceHelpTemplate/loading';
import DeviceHelpTemplate from 'components/template/DeviceHelpTemplate';
import { DeviceHelpTextInputProps } from 'components/pages/AssetPage/Tabs/TabAccesses/resources/useDeviceHelpDrawer/models';
import DefaultButton from 'components/atoms/Button/DefaultButton';
import PrimaryButton from 'components/atoms/Button/PrimaryButton';
import DrawerAtom from 'components/atoms/DrawerAtom';
import TextAtom from 'components/atoms/TextAtom';
import SkeletonAtom from 'components/atoms/SkeletonAtom';
import './styles.scss';

const DRAWER_ATOM_WIDTH = '520px';
interface Props {
  title: string;
  subtitle: string;
  closeTextButton: string;
  saveTextButton: string;
  formDeviceHelpDrawerDescriptionState: DeviceHelpTextInputProps;
  formDeviceHelpDrawerTitleState: DeviceHelpTextInputProps;
  onActiveInfo: (isActive: boolean) => void;
  onClose: () => void;
  onEdit: () => void;
  onSave: () => void;
  isEdit: boolean;
  isVisible: boolean;
  isLoadingCharge: boolean;
  isLoadingSave: boolean;
  isActiveInfo: boolean;
  isDisabledSaveButton: boolean;
  isDisabled: boolean;
}

const DeviceHelpDrawer: FC<Props> = ({
  title,
  subtitle,
  closeTextButton,
  saveTextButton,
  formDeviceHelpDrawerTitleState,
  formDeviceHelpDrawerDescriptionState,
  onActiveInfo,
  onClose,
  onEdit,
  onSave,
  isVisible,
  isEdit,
  isLoadingCharge,
  isLoadingSave,
  isActiveInfo,
  isDisabledSaveButton,
  isDisabled,
}) => {
  return (
    <DrawerAtom
      width={DRAWER_ATOM_WIDTH}
      className={'DeviceHelpDrawer__wrapper'}
      title={
        <TextAtom
          style={{ color: COLORS.text }}
        >
          {isLoadingCharge && <SkeletonAtom.Input active />}
          {!isLoadingCharge && title}
        </TextAtom>}
      destroyOnClose={true}
      closable={!isLoadingSave}
      maskClosable={!isLoadingSave}
      onClose={onClose}
      placement={'right'}
      size={'default'}
      open={isVisible}
      style={{ zIndex: 2 }}
    >
      <>
        {isLoadingCharge && (
          <DeviceHelpLoadingTemplate
            title={subtitle}
            formDeviceHelpDrawerDescriptionState={formDeviceHelpDrawerDescriptionState}
            formDeviceHelpDrawerTitleState={formDeviceHelpDrawerTitleState}
          />
        )}
        {!isLoadingCharge && (
          <DeviceHelpTemplate
            title={subtitle}
            formDeviceHelpDrawerDescriptionState={formDeviceHelpDrawerDescriptionState}
            formDeviceHelpDrawerTitleState={formDeviceHelpDrawerTitleState}
            onEditForm={onActiveInfo}
            isEditForm={isActiveInfo}
            isVisiblePreviewInfo={!isDisabledSaveButton && isActiveInfo}
          />
        )}
      </>
      <footer className='DeviceHelpDrawer__footer'>
        <DefaultButton
          onClick={onClose}
          title={closeTextButton}
        />
        <PrimaryButton
          loading={isLoadingSave}
          title={saveTextButton}
          onClick={isEdit ? onEdit : onSave}
          disabled={isDisabledSaveButton || isLoadingSave || isDisabled}
        />
      </footer>
    </DrawerAtom>
  )
}

export default DeviceHelpDrawer;