import {
  DeviceHelpDataPayloadResponse,
  DeviceHelpDataModel,
  CreateDeviceHelpDataPayload,
} from '../models';

export const deviceHelpDataPayloadToModel = (item: DeviceHelpDataPayloadResponse): DeviceHelpDataModel => {
  const { data } = item;
  const { title, description, gateway_id, device_id } = data;

  return {
    title,
    description,
    deviceId: device_id,
    gatewayId: gateway_id,
  };
}

export const deviceHelpDataModelToPayload = ({
  title,
  description,
  deviceId,
  gatewayId
}: DeviceHelpDataModel ): CreateDeviceHelpDataPayload => {
  return {
    attributes: {
      title,
      description,
      device_id: deviceId,
      gateway_uid: gatewayId,
    }
  }
}