import { FC } from 'react';
import DashboardUsersDefaultTemplate from './default';
import DashboardUsersLoadingTemplate from './loading';
import DashboardUsersErrorTemplate from './error';
import { ActiveUsersDashboardProps } from 'components/pages/DashboardPage/resources/models';

const DashboardActiveUsersTemplate: FC<ActiveUsersDashboardProps> = (props) => {
  const { isError, isLoading } = props;
  if (isError) {
    return <DashboardUsersErrorTemplate {...props} />
  }
  if (isLoading) {
    return <DashboardUsersLoadingTemplate />
  }
  return (
    <DashboardUsersDefaultTemplate {...props} />
  )
}

export default DashboardActiveUsersTemplate;