import { FC } from 'react';
import TabTemplate from 'components/template/TabTemplate';
import { useSharedGateway } from './resources/useSharedGateway';
import GatewayStatus from 'components/pages/AssetPage/Tabs/TabGateway/GatewayStatus';

const TabInfo: FC = () => {
  const {
    gateway,
    translations,
    onUpdateGateway,
    loading,
    hasPermissionToEditGatewayLink,
  } = useSharedGateway();

  return (
    <TabTemplate loading={loading}>
      {!!gateway?.status && (
        <GatewayStatus
          gateway={gateway}
          informationTranslations={translations}
          onUnassignGateway={() => { }}
          onUpdateGateway={onUpdateGateway}
          hasPermissionToEditGatewayLink={hasPermissionToEditGatewayLink}
          isGatewayLinked={true}
        />
      )}
    </TabTemplate>
  );
};

export default TabInfo;
